import React, { FC } from 'react';

// Styles:
import { colors } from 'styles/theme';

// Atoms:
import { ASimple, Icon } from 'atoms';

// ===================================================================
interface Props {
  id: string;
  centered?: boolean;
  isDisabled?: boolean;
  status: boolean;
  setStatus: (status: boolean) => void;
  label?: string | null;
  labelColor?: string;
  labelFontSize?: string;
  boxSize?: string;
}

// ===================================================================
export const Checkbox: FC<Props> = ({
  centered = true,
  id,
  isDisabled,
  status,
  setStatus,
  label = null,
  boxSize = '1.75rem',
  labelColor = 'grey',
  labelFontSize = '1rem'
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: centered ? 'center' : 'flex-start',
      opacity: isDisabled ? 0.5 : 1
    }}
  >
    {/* Label */}
    {label && (
      <label
        onClick={() => {
          if (!isDisabled) setStatus(!status);
        }}
        htmlFor={id}
        style={{
          fontSize: labelFontSize,
          color: colors[labelColor],
          fontWeight: 500,
          display: 'flex',
          cursor: isDisabled ? 'not-allowed' : 'pointer'
        }}
      >
        {/* Box */}
        <div
          id={id}
          style={{
            alignItems: 'center',
            background: status ? colors.lightBlue : colors.transparent,
            border: 'solid 2px ' + (status ? colors.lightBlue : colors.disabled),
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            display: 'flex',
            height: boxSize,
            justifyContent: 'center',
            marginRight: '1rem',
            width: boxSize
          }}
          onClick={() => {
            if (!isDisabled) setStatus(!status);
          }}
        >
          {status && (
            <ASimple
              key="Checkbox"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Icon type="check" size="70%" />
            </ASimple>
          )}
        </div>

        {label}
      </label>
    )}
  </div>
);
