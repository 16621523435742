import React, { FC, useContext, useEffect, useState, useCallback } from 'react';

// Components
import { AEditorMenuItem } from 'atoms';
import { ErrorMessage, SearchResults, SelectedBoard, SelectedCategory, SelectedDevice } from 'page/Editor/Menu/Shared';
import { MasterlineHome, MasterlineFunctional, MasterlineGlobal, MasterlineStructure, MasterlineSubstructure, MasterlineUpperStructure } from './Partials';

import Block from 'page/Editor/configuration/Block';
import BlockItem from 'page/Editor/configuration/BlockItem';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import BlockBoard from 'page/Editor/configuration/BlockBoard';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Types
import { Category } from 'types';

// Utils:
import { getCategories, hasOwnProperty, isDevice, updateBlockIds } from 'utils';

// ===================================================================

// ===================================================================
export const Masterline: FC = () => {
  const { lang } = useContext(LangContext);
  const {
    configuration,
    coverEnlargementSize,
    errorAlert,
    fullscreen,
    masterlineView,
    menuView,
    searchResults,
    scene,
    selectedMasterline,
    selection,
    setCanAdvanceMasterline,
    setCoverEnlargementSize,
    setErrorAlert,
    setHasMasterline,
    setMasterlineView,
    setModularView,
    setModularNOLView,
    setSelectedMasterline,
    setSelection
  } = useContext(EditorContext);

  /* State */
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [fullscreenIsMenu, setFullscreenIsMenu] = useState<boolean>(false);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Delete') {
        if (selection && selection instanceof BlockItem) {
          setErrorAlert(['delete']);
        }
      }
    },
    [selection]
  );

  useEffect(() => {
    const masterlines: Block | Block[] = configuration.getMasterline();
    if (Array.isArray(masterlines)) {
      if (!(selection instanceof BlockBoard)) setSelectedMasterline(masterlines[0]);
    }
    // Fallback for old configs
    else {
      if (masterlines) {
        setSelectedMasterline(masterlines);
      }
    }
  }, [configuration]);

  useEffect(() => {
    if (selection) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selection]);

  /* Fetch Categories */
  useEffect(() => {
    const fetchCategories = async ({ isUpperStructureOnly }: { isUpperStructureOnly: boolean }) => {
      const { data, error } = await getCategories('masterline', undefined, isUpperStructureOnly);
      if (data) {
        setCategories(data);
      }
      if (error) {
        console.log('Error fetching Categories with count: ', error);
      }
    };
    fetchCategories({ isUpperStructureOnly: selectedMasterline?.getUpperstrcutureOnly() || false });
  }, [selectedMasterline]);

  useEffect(() => {
    if (fullscreen === 'menu') {
      setFullscreenIsMenu(true);
    }
    if (fullscreen !== 'menu' && fullscreenIsMenu) {
      if (scene && scene.getSelected && scene.getSelected() instanceof BlockItem) {
        setSelectedCategory(null);
        setSelection(scene.getSelected());
      }
      setFullscreenIsMenu(false);
    }
  }, [fullscreen, fullscreenIsMenu]);

  useEffect(() => {
    // reset all Selections and Submenus
    //if (selection) setSelection(null);
    if (selectedCategory) setSelectedCategory(null);
    if (fullscreenIsMenu) setFullscreenIsMenu(false);
    if (errorAlert) setErrorAlert(null);
    return () => {
      setFullscreenIsMenu(false);
      setSelectedCategory(null);
      setErrorAlert(null);
    };
  }, [menuView, masterlineView]);

  const selectionOrError =
    (Array.isArray(errorAlert) &&
      (errorAlert.includes('size') ||
        errorAlert.includes('delete') ||
        errorAlert.includes('dimSum') ||
        errorAlert.includes('coverEnlargement') ||
        errorAlert.includes('marginRounded') ||
        errorAlert.includes('fryer'))) ||
    selectedCategory ||
    selection ||
    searchResults;

  if (!(menuView === 'masterline')) {
    return <></>;
  }

  if (searchResults && !errorAlert?.includes('size') && !selection && menuView === 'masterline') {
    return (
      <AEditorMenuItem key="Masterline-Searchresults">
        <SearchResults />
      </AEditorMenuItem>
    );
  }

  /* Render */
  return (
    <>
      {/* Size Error */}
      {Array.isArray(errorAlert) && errorAlert.length === 1 && errorAlert[0] === 'size' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessage">
          <ErrorMessage title={Localizations['sizeConflict'][lang]} message={Localizations['sizeConflictMessage'][lang]} goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {/* G Oven Hint */}
      {Array.isArray(errorAlert) && errorAlert.length === 1 && errorAlert[0] === 'gOven' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageGoven">
          <ErrorMessage title={Localizations['hintHeader'][lang]} message={Localizations['gOvenHint'][lang]} goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {/* FreyerHint */}
      {Array.isArray(errorAlert) && errorAlert.length === 1 && errorAlert[0] === 'fryer' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageFryer">
          <ErrorMessage title={Localizations['hintHeader'][lang]} message={Localizations['fryerHint'][lang]} goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {/* FlexiHint */}
      {Array.isArray(errorAlert) && errorAlert.length === 1 && errorAlert[0] === 'flexiMasterlineHint' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageFryer">
          <ErrorMessage title={Localizations['hintHeader'][lang]} message={Localizations['flexiMasterlineHint'][lang]} goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {Array.isArray(errorAlert) && errorAlert.length === 1 && errorAlert[0] === 'cooling' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageFryer">
          <ErrorMessage title={Localizations['hintHeader'][lang]} message={Localizations['coolingHint'][lang]} goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {Array.isArray(errorAlert) && errorAlert.length === 1 && errorAlert[0] === 'salamander' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageSalamander">
          <ErrorMessage title={Localizations['hintHeader'][lang]} message={Localizations['salamanderHint'][lang]} goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {Array.isArray(errorAlert) && errorAlert.length === 1 && errorAlert[0] === 'dimSum' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageDimSum">
          <ErrorMessage title={Localizations['hintHeader'][lang]} message={Localizations['dimSumHint'][lang]} goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {/* CutHint */}
      {Array.isArray(errorAlert) && errorAlert.length === 1 && errorAlert[0] === 'cuts' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageCuts">
          <ErrorMessage title={Localizations['hintHeader'][lang]} message={Localizations['blockCutHint'][lang]} goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {/* Socket Hint */}
      {Array.isArray(errorAlert) && errorAlert.length === 1 && errorAlert[0] === 'powerSocket' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageSockets">
          <ErrorMessage title={Localizations['hintHeader'][lang]} message={Localizations['socketHint'][lang]} goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {/* Rounded Error */}
      {Array.isArray(errorAlert) && errorAlert.length === 1 && errorAlert[0] === 'marginRounded' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageRounded">
          <ErrorMessage title={Localizations['hintHeader'][lang]} message={Localizations['marginRoundedHint'][lang]} goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {/* CoverEnlargementHint */}
      {Array.isArray(errorAlert) && errorAlert.length === 1 && errorAlert[0] === 'coverEnlargement' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageCover">
          <ErrorMessage
            title={Localizations['coverEnlargementHeader'][lang]}
            message={Localizations['coverEnlargementHint'][lang] + coverEnlargementSize}
            goBack={() => {
              setErrorAlert(null);
              setCoverEnlargementSize('');
            }}
          />
        </AEditorMenuItem>
      )}

      {/* Multiple Errors */}
      {Array.isArray(errorAlert) && errorAlert.length > 1 && !errorAlert.includes('delete') && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageMultiple">
          <ErrorMessage goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {/* Delete Confirm */}
      {Array.isArray(errorAlert) && errorAlert.length > 0 && errorAlert.includes('delete') && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-ErrorMessageDelete">
          <ErrorMessage
            title={Localizations['delete'][lang]}
            message={Localizations['confirmDelete'][lang]}
            confirm={() => {
              if ((masterlineView === 'structure' && selectedMasterline) || (scene && scene.getSelected() && scene.getSelected() instanceof Block)) {
                const index = configuration.getMasterline().findIndex((b: Block) => b.getId() === selectedMasterline.getId());
                configuration.removeMasterline(index);
                if (!configuration.getMasterline() || (configuration.getMasterline() && configuration.getMasterline().length === 0)) {
                  setSelectedMasterline(null);
                } else {
                  setSelectedMasterline(configuration.getMasterline()[0]);
                }
                const masterlines = configuration.getMasterline();
                setHasMasterline(!!(Array.isArray(masterlines) && masterlines.length > 0));
                updateBlockIds(masterlines, 'masterline');
              } else if (selection instanceof BlockItem || selection instanceof BlockGroup) {
                selection.delete();
                setSelection(null);
                const masterlines = configuration.getMasterline();

                updateBlockIds(masterlines, 'masterline');

                window.removeEventListener('keydown', handleKeyDown);
              } else {
                console.log('can not delete');
                setSelection(null);
                /* if (scene.getSelected()) {
                  scene.getSelected().delete();
                  window.removeEventListener('keydown', handleKeyDown);
                } */
              }
              if (configuration && masterlineView !== 'structure') {
                if (selectedMasterline && selectedMasterline.getRowBottom() && selectedMasterline.getRowBottom().getItems().length === 0) {
                  if (
                    !selectedMasterline.getRowTop() ||
                    (selectedMasterline.getRowTop() && selectedMasterline.getRowTop().getItems() && selectedMasterline.getRowTop().getItems().length === 0)
                  ) {
                    setCanAdvanceMasterline(false);
                    setMasterlineView('home');
                    setModularView('home');
                    setModularNOLView('home');
                  }
                }
              }
              setSelection(null);
              setErrorAlert(null);
              scene.setSelected(null);
            }}
            goBack={() => setErrorAlert(null)}
          />
        </AEditorMenuItem>
      )}

      {/* Block */}
      {selection && selection instanceof Block && masterlineView !== 'structure' && masterlineView !== 'substructures' && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-Structure-Block">
          <MasterlineStructure />
          {/* <SelectedBlock setView={setView} /> */}
        </AEditorMenuItem>
      )}

      {/* Board */}
      {!(Array.isArray(errorAlert) && (errorAlert.includes('salamander') || errorAlert.includes('marginRounded'))) &&
        ((selection && selection instanceof BlockBoard && masterlineView !== 'substructures' && menuView === 'masterline') ||
          masterlineView === 'shelfMenu') && (
          <AEditorMenuItem key="Masterline-Board">
            <SelectedBoard />
          </AEditorMenuItem>
        )}

      {/* Device 1 */}
      {selection &&
        (selection instanceof BlockItem || selection instanceof BlockGroup) &&
        !(masterlineView === 'substructures') &&
        !(masterlineView === 'upperStructures') &&
        !fullscreen &&
        !(
          Array.isArray(errorAlert) &&
          (errorAlert.includes('size') || errorAlert.includes('delete') || errorAlert.includes('fryer') || errorAlert.includes('coverEnlargement'))
        ) &&
        menuView === 'masterline' && (
          <AEditorMenuItem key="Masterline- Device - 1" style={{ position: 'absolute', zIndex: 9001, top: 0 }}>
            {selection instanceof BlockItem ? (
              <SelectedDevice isOverlay selectedDevice={[selection._device]} />
            ) : (
              <SelectedDevice selectedDevice={selection.getItems().map(item => item.getDeviceObject())} />
            )}
          </AEditorMenuItem>
        )}
      {/* Device 2 */}
      {selection &&
        hasOwnProperty(selection, 'type') &&
        isDevice(selection) &&
        !(masterlineView === 'substructures') &&
        !(masterlineView === 'upperStructures') &&
        !fullscreen &&
        isDevice(selection) &&
        !(
          Array.isArray(errorAlert) &&
          (errorAlert.includes('size') || errorAlert.includes('delete') || errorAlert.includes('fryer') || errorAlert.includes('coverEnlargement'))
        ) &&
        menuView === 'masterline' && (
          <AEditorMenuItem key=" Masterline- Device - 2" style={{ position: 'absolute', zIndex: 9001, top: 0 }}>
            {/* @ts-ignore */}
            <SelectedDevice isOverlay selectedDevice={[selection]} setSelection={setSelection} />
          </AEditorMenuItem>
        )}
      {/* Category */}
      {selectedCategory &&
        (!selection || (selection && hasOwnProperty(selection, 'type') && isDevice(selection))) &&
        !fullscreenIsMenu &&
        !(Array.isArray(errorAlert) && (errorAlert.includes('delete') || errorAlert.includes('fryer') || errorAlert.includes('coverEnlargement'))) &&
        menuView === 'masterline' && (
          <AEditorMenuItem key="Masterline-Category" style={{ overflow: 'hidden' }}>
            <SelectedCategory setSelection={setSelection} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
          </AEditorMenuItem>
        )}

      {/* Home */}
      {masterlineView === 'home' && !selectionOrError && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-home">
          <MasterlineHome />
        </AEditorMenuItem>
      )}

      {/* Structure */}
      {masterlineView === 'structure' &&
        !(Array.isArray(errorAlert) && (errorAlert.includes('size') || errorAlert.includes('delete') || errorAlert.includes('dimSum'))) &&
        !(selection && (selection instanceof BlockItem || selection instanceof BlockGroup)) &&
        menuView === 'masterline' && (
          <AEditorMenuItem key="Masterline-structure">
            <MasterlineStructure />
          </AEditorMenuItem>
        )}

      {/* Functional */}
      {masterlineView === 'functional' && !selectionOrError && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-functional" style={{ overflow: 'hidden' }}>
          <MasterlineFunctional categories={categories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
        </AEditorMenuItem>
      )}

      {/* Functional */}
      {masterlineView === 'upperStructures' && !(Array.isArray(errorAlert) && errorAlert.includes('size')) && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-upperStructures" style={{ overflow: 'hidden' }}>
          <MasterlineUpperStructure />
        </AEditorMenuItem>
      )}

      {/* Substructures */}
      {masterlineView === 'substructures' && !(Array.isArray(errorAlert) && errorAlert.includes('size')) && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-substructures" style={{ overflow: 'hidden' }}>
          <MasterlineSubstructure />
        </AEditorMenuItem>
      )}

      {/* Structure */}
      {masterlineView === 'global' && !selectionOrError && menuView === 'masterline' && (
        <AEditorMenuItem key="Masterline-global" style={{ overflow: 'hidden' }}>
          <MasterlineGlobal selectedCategory={selectedCategory} />
        </AEditorMenuItem>
      )}
    </>
  );
};
