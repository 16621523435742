import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import BlockItem from '../page/Editor/configuration/BlockItem';
import BlockGroup from '../page/Editor/configuration/BlockGroup';

// 'UpperDrawer', 'UpperPowerSocket', 'MixingFaucet', 'ColdWaterInlet', 'DoubleSidedOperation'

export const canHaveUpperstructures = (block: BlockItem | BlockGroup): boolean => {
  if (!block || !(block instanceof BlockItem || block instanceof BlockGroup)) return false;
  if (EquipmentHelper.canHaveUpperDrawer(block)) return true;
  if (EquipmentHelper.canHaveUpperPowerSocket(block)) return true;
  if (EquipmentHelper.canHaveMixingFaucet(block)) return true;
  if (EquipmentHelper.canHaveColdWaterInlet(block)) return true;
  if (EquipmentHelper.canHaveDoubleSidedOperation(block)) return true;
  if (block instanceof BlockItem) return block.getDeviceObject().style?.includes('rotatableUpperStructure');
  return false;
};
