import { useEffect } from 'react';
import Block from 'page/Editor/configuration/Block';
import { Action } from '../index';
import { MasterlineTypes } from 'page/Editor/Menu/Menus/Masterline/Partials';

export const useInitStructure = (
  selectedMasterline: Block,
  initialized: boolean,
  lang: string,
  ROWS: MasterlineTypes,
  setSelectedRow: React.Dispatch<React.SetStateAction<string>>,
  setInitialized: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedSeries: React.Dispatch<React.SetStateAction<string>>,
  setCoverEnlargement: React.Dispatch<React.SetStateAction<number>>,
  dispatch: React.Dispatch<Action>,
  setIsUpperStructureOnly: React.Dispatch<React.SetStateAction<boolean>>,
  setDescription: React.Dispatch<React.SetStateAction<string>>,
  setIsAutoHygiene: React.Dispatch<React.SetStateAction<boolean>>
) => {
  useEffect(() => {
    if (!initialized && selectedMasterline) {
      let initialRow: string = '';
      let inititalSeries: string = '';

      // Rows
      if (selectedMasterline.getType() === 'Double') {
        initialRow = ROWS.double;
      } else {
        if (selectedMasterline.getSingleType() === 'Free') {
          initialRow = ROWS.singleFree;
        } else {
          initialRow = ROWS.singleWall;
        }
      }
      // Series
      const rowTop = selectedMasterline.getRowTop();
      const rowBottom = selectedMasterline.getRowBottom();
      if (selectedMasterline.getType() === 'Double') {
        const topDepth = rowTop.getDepth();
        const bottomDepth = rowBottom.getDepth();
        if (topDepth == 850 && bottomDepth === 850) {
          inititalSeries = '850 / 850';
        } else if (topDepth === 700 && bottomDepth === 850) {
          inititalSeries = '700 / 850';
        } else if (topDepth === 850 && bottomDepth === 850) {
          inititalSeries = '850 / 700';
        } else if (topDepth === 700 && bottomDepth === 700) {
          inititalSeries = '700 / 700';
        }
      } else {
        if (rowBottom.getDepth() === 700) {
          if (selectedMasterline.getSingleType() === 'Free') {
            inititalSeries = '740';
          } else {
            inititalSeries = '700';
          }
        } else {
          if (selectedMasterline.getSingleType() === 'Free') {
            inititalSeries = '890';
          } else {
            inititalSeries = '850';
          }
        }
      }

      // Done
      setSelectedRow(initialRow);
      setInitialized(true);
      setSelectedSeries(inititalSeries);
      setCoverEnlargement(selectedMasterline.getDepthExtension());
      dispatch({ type: 'changeSelectedBlock', payload: selectedMasterline });
      setIsUpperStructureOnly(selectedMasterline.getUpperstrcutureOnly());
      setDescription(selectedMasterline.getDescription() ?? '');
      setIsAutoHygiene(selectedMasterline.isAutoHygiene());
    }
  }, [selectedMasterline, initialized, lang]);
};
