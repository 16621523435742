const LMenu = {
  groundPlan: {
    fr: 'Plan du sol',
    de: 'Grundriss',
    en: 'Ground Plan',
    cz: 'Půdorys'
  },
  doors: {
    fr: 'Portes / Passages',
    de: 'Türen / Durchgänge',
    en: 'Doors / Passages',
    cz: 'Dveře / průchody'
  },
  door: {
    fr: 'Portes ',
    de: 'Tür',
    en: 'Door',
    cz: 'Dveře'
  },
  startPage: {
    fr: 'Accueil',
    de: 'Start',
    en: 'Home',
    cz: 'Start'
  },
  window: {
    fr: 'Fenêtre',
    de: 'Fenster',
    en: 'Window',
    cz: 'Okno'
  },
  discounts: {
    fr: 'Remises',
    de: 'Rabatte',
    en: 'Discounts',
    cz: 'Slevy'
  },
  totalPrice: {
    fr: 'Total',
    de: 'Gesamt',
    en: 'Total',
    cz: 'Celkem'
  },
  totalPriceRight: {
    fr: 'Prix total',
    de: 'Gesamtpreis',
    en: 'Total Price',
    cz: 'Celková cena'
  },
  singlePriceRight: {
    fr: 'Prix individuels',
    de: 'Einzelpreise',
    en: 'Single Prices',
    cz: 'jednotná cena'
  },
  addUser: {
    fr: 'Ajouter un utilisateur',
    de: 'Benutzer hinzufügen',
    en: 'Add user',
    cz: 'Add user'
  },
  users: {
    fr: 'Utilisateurs',
    de: 'Benutzer',
    en: 'Users',
    cz: 'Users'
  },
  organization: {
    fr: 'Organisation',
    de: 'Organisation',
    en: 'Organization',
    cz: 'Organization'
  },
  edit: {
    fr: 'Editer',
    de: 'Bearbeiten',
    en: 'Edit',
    cz: 'Upravovat'
  },
  windows: {
    fr: 'Fenêtres',
    de: 'Fenster',
    en: 'Windows',
    cz: 'Okna'
  },
  walls: {
    fr: 'Murs',
    de: 'Wände',
    en: 'Walls',
    cz: 'Stěny'
  },
  wall: {
    fr: 'Mur',
    de: 'Wand',
    en: 'Wall',
    cz: 'Stěna'
  },
  pillar: {
    fr: 'Pilier',
    de: 'Säule',
    en: 'Pillar',
    cz: 'Sloup'
  },
  pillars: {
    fr: 'Piliers',
    de: 'Säulen',
    en: 'Pillars',
    cz: 'Sloupy'
  },
  width: {
    fr: 'Largeur',
    de: 'Breite',
    en: 'Width',
    cz: 'Šířka'
  },
  height: {
    fr: 'Hauteur',
    de: 'Höhe',
    en: 'Height',
    cz: 'Výška'
  },
  addMore: {
    fr: "Plus d'informations",
    de: 'Weitere Hinzufügen',
    en: 'Add More',
    cz: 'Přidat další'
  },
  loadMore: {
    fr: 'En savoir plus',
    de: 'Mehr laden',
    en: 'Load more',
    cz: 'Načíst další'
  },
  floorDistance: {
    fr: 'Distance au sol',
    de: 'Bodenabstand',
    en: 'Floor Distance',
    cz: 'Vzdálenost od podlahy'
  },
  length: {
    fr: 'Longueur',
    de: 'Länge',
    en: 'Length',
    cz: 'Délka'
  },
  rotation: {
    fr: 'Rotation',
    de: 'Drehung',
    en: 'Rotation',
    cz: 'Otáčení'
  },
  degrees: {
    fr: 'degrés',
    de: 'Grad',
    en: 'Deg.',
    cz: 'Stupně'
  },
  square: {
    fr: 'Carré',
    de: 'Eckig',
    en: 'Square',
    cz: 'Hranatý'
  },
  round: {
    fr: 'Arrondis',
    de: 'Rund',
    en: 'Round',
    cz: 'Kulatý'
  },
  add: {
    fr: 'Ajouter',
    de: 'Hinzufügen',
    en: 'Add',
    cz: 'Přidat'
  },
  details: {
    fr: 'Détails',
    de: 'Details',
    en: 'Details',
    cz: 'Detaily'
  },
  connectedLoads: {
    fr: 'Charges connectées',
    de: 'Anschlusswerte',
    en: 'Connected Loads',
    cz: 'Přípojné hodnoty'
  },
  operationMode: {
    fr: 'Mode de fonctionnement',
    de: 'Betriebsart',
    en: 'Operation Mode',
    cz: 'Provozní režim'
  },
  series: {
    fr: 'Série',
    de: 'Serie',
    en: 'Series',
    cz: 'Série'
  },
  attachments: {
    fr: 'Annexes',
    de: 'Zubehör',
    en: 'Attachments',
    cz: 'Příslušenství'
  },
  specialEquipment: {
    fr: 'Équipements spéciaux',
    de: 'Sonderausstattung',
    en: 'Special Equipment',
    cz: 'Speciální vybavení'
  },
  loadMasterline: {
    fr: 'Charger Masterline',
    de: 'Masterline Laden',
    en: 'Load Masterline',
    cz: 'Nahrát Masterline'
  },
  saveMasterline: {
    fr: 'Sauvegarder Masterline',
    de: 'Masterline speichern',
    en: 'Save Masterline',
    cz: 'Uložit Masterline'
  },
  load: {
    fr: 'Chargement',
    de: 'Laden',
    en: 'Load',
    cz: 'Nahrát'
  },
  save: {
    fr: 'Sauvegarder',
    de: 'Speichern',
    en: 'Save',
    cz: 'Uložit'
  },
  saveFailed: {
    fr: "L'enregistrement a échoué",
    de: 'Speichern misslungen',
    en: 'Save failed',
    cz: 'Uložení selhalo'
  },
  saveFailedDesc: {
    fr: "La configuration n'a pas pu être sauvegardée. Veuillez réessayer.",
    de: 'Konfiguration konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.',
    en: 'Configuration could not be saved. Please try again.',
    cz: 'Konfigurace nemohla být uložena. Zkuste to prosím znovu.'
  },
  blockStructure: {
    fr: 'Structure des blocs',
    de: 'Blockaufbau',
    en: 'Block-Structure',
    cz: 'Bloková struktura'
  },
  structure: {
    fr: 'Structure',
    de: 'Aufbau',
    en: 'Structure',
    cz: 'Struktura'
  },
  functionalDevices: {
    fr: 'Appareils fonctionnels',
    de: 'Funktionsgeräte',
    en: 'Functional appliances',
    cz: 'Funkční přístroje'
  },
  upperStructures: {
    fr: 'Options de la structure supérieure',
    de: 'Oberbau Optionen',
    en: 'Upper Structure Options',
    cz: 'Možnosti pro horní konstrukci'
  },
  upperStructureOnly: {
    fr: 'Seulement la structure supérieure',
    de: 'Nur Oberbau',
    en: 'Only upper structure',
    cz: 'Pouze horní konstrukce'
  },
  substructureOptions: {
    fr: 'Options de la sous-structure',
    de: 'Unterbau Optionen',
    en: 'Substructure Options',
    cz: 'Možnosti pro spodní konstrukci'
  },
  connectSubstructure: {
    fr: 'Connecter les modules',
    de: 'Unterbau verbinden',
    en: 'Connect cabinets',
    cz: 'Spojit spodní konstrukci'
  },
  noSubtypes: {
    fr: "Il n'y a pas de sous-structures pour ce dispositif.",
    de: 'Für dieses Gerät sind keine Unterbauoptionen verfügbar.',
    en: 'There are no substructures for this device.',
    cz: 'Pro tento přístroj nejsou k dispozici žádné možnosti pro spodní konstrukci.'
  },
  removeConnection: {
    fr: 'Déconnecter les modules',
    de: 'Verbindung lösen',
    en: 'Disconnect cabinets',
    cz: 'Uvolnit spojení'
  },
  globalSettings: {
    fr: 'Paramètres généraux',
    de: 'Globale Ausstattung',
    en: 'Global Settings',
    cz: 'Globální vybavení'
  },
  sizeConflict: {
    fr: 'Conflit de taille',
    de: 'Größenkonflikt',
    en: 'Size Conflict',
    cz: 'Konflikt velikostí'
  },
  sizeConflictMessage: {
    fr: "L'appareil ne peut pas être placé à cet endroit parce que le bloc choisi a une profondeur différente.",
    de: 'An dieser Stelle kann das Gerät nicht platziert werden, da der ausgewählte Küchenblock eine andere Tiefe hat.',
    en: 'Device can not be placed there because the chosen block has a different depth.',
    cz: 'Na toto místo nelze přístroj umístit, protože zvolený kuchyňský blok má jinou hloubku.'
  },
  noDevice: {
    fr: 'Aucun appareil trouvé.',
    de: 'Kein Gerät gefunden.',
    en: 'No Device found.',
    cz: 'Nebyl nalezen žádný přístroj.'
  },
  continue: {
    fr: 'Continuer',
    de: 'Weiter',
    en: 'Continue',
    cz: 'Dále'
  },
  panel: {
    fr: 'Tableau de commande joint',
    de: 'Gemeinsame Schalterblende',
    en: 'Joined Control Board',
    cz: 'Společná ovládací clona'
  },
  colorSB: {
    fr: 'Couleur (panneau de commande)',
    de: 'Farbige Schalterblende',
    en: 'Color (operation panel)',
    cz: 'Barevná ovládací clona'
  },
  handlebar: {
    fr: 'Barre de maintien',
    de: 'Griffstange',
    en: 'Hand rail',
    cz: 'Madlo'
  },
  handlebarPosition: {
    fr: 'Position de la barre de maintien',
    de: 'Griffstangenposition',
    en: 'Hand rail position',
    cz: 'Madlo pozice'
  },
  Top: {
    fr: 'arrière',
    de: 'Hinten',
    en: 'back',
    cz: 'back'
  },
  Bottom: {
    fr: 'avant',
    de: 'Vorne',
    en: 'front',
    cz: 'front'
  },
  Both: {
    fr: 'avant et arrière',
    de: 'Beidseitig',
    en: 'front and back',
    cz: 'front and back'
  },
  Full: {
    fr: 'entourage',
    de: 'Umlaufend',
    en: 'surrounding',
    cz: 'surrounding'
  },
  noShelf: {
    fr: "Pas d'étagère",
    de: 'Kein Aufsatzbord',
    en: 'No Shelf',
    cz: 'Bez nástavby'
  },
  shelf: {
    fr: 'Étagère',
    de: 'Aufsatzbord',
    en: 'Shelf',
    cz: 'Nástavba'
  },
  mediaShelf: {
    fr: 'Media Shelf',
    de: 'Medienbord',
    en: 'Media Shelf',
    cz: 'Nástavba pro média'
  },
  salamanderShelf: {
    fr: 'Étagère Salamandre',
    de: 'Salamanderbord',
    en: 'Salamander Shelf',
    cz: 'Nástavba Salamander'
  },
  shelfHint: {
    fr: "Lors de l'ajout de la parisienne, la largeur du bloc est automatiquement augmentée de 100 mm.",
    de: 'Bei Einsetzen des Doppelbordes wird der Doppelblock automatisch um 100 mm in der Mitte verbreitert.',
    en: 'When adding the double board the width of the block will bei extended by 100 mm automatically.',
    cz: 'Při použití dvojité nástavby se dvojblok uprostřed automaticky rozšíří o 100 mm.'
  },
  shelfOptions: {
    fr: 'Paramètres',
    de: 'Einstellungen',
    en: 'Settings',
    cz: 'Nastavení'
  },
  edgeDistance: {
    fr: 'Distance du bord',
    de: 'Randabstand',
    en: 'Edge Distance',
    cz: 'Vzdálenost od okraje'
  },
  right: {
    fr: 'Droite',
    de: 'Rechts',
    en: 'Right',
    cz: 'Vpravo'
  },
  left: {
    fr: 'Gauche',
    de: 'Links',
    en: 'Left',
    cz: 'Vlevo'
  },
  oven: {
    fr: 'Four',
    de: 'Ofen',
    en: 'Oven',
    cz: 'Trouba'
  },
  ovenQuestion: {
    fr: 'Voulez-vous ajouter un four ?',
    de: 'Möchten Sie einen Ofen hinzufügen?',
    en: 'Do you want to add an oven?',
    cz: 'Chcete přidat troubu?'
  },
  dontAddOven: {
    fr: 'Ne pas ajouter de four',
    de: 'Keinen Ofen hinzufügen',
    en: 'Do not add oven',
    cz: 'Nepřidávat troubu'
  },
  addOven: {
    fr: 'Ajouter un four',
    de: 'Ofen hinzufügen',
    en: 'Add Oven',
    cz: 'Přidat troubu'
  },
  socket: {
    fr: 'Prise de courant',
    de: 'Steckdose',
    en: 'Power Socket',
    cz: 'Zásuvka'
  },
  sockets: {
    fr: 'Prises de courant',
    de: 'Steckdosen',
    en: 'Power Sockets',
    cz: 'Zásuvky'
  },
  socketHint: {
    fr: 'Lorsque la commande est passée, il convient de vérifier et de confirmer que le nombre de prises sélectionné peut être placé.',
    de: 'Im Auftragsfall ist zu prüfen ob die entsprechende Anzahl von Steckdosen platziert werden kann.',
    en: 'When the order is placed it needs to be checked and confirmed if the selected number of sockets can be placed.',
    cz: 'V případě objednání je nutné zkontrolovat, jestli lze umístit příslušný počet zásuvek.'
  },
  socketQuestion: {
    fr: 'Souhaitez-vous ajouter une prise de courant ?',
    de: 'Möchten Sie eine Steckdose hinzufügen?',
    en: 'Do you want to add a power socket?',
    cz: 'Chcete přidat zásuvku?'
  },
  dontAddSocket: {
    fr: 'Ne pas ajouter de prise de courant',
    de: 'Keine Steckdose hinzufügen',
    en: 'Do not add power socket',
    cz: 'Nepřidávat zásuvku'
  },
  addSocket: {
    fr: 'Ajouter une prise de courant',
    de: 'Steckdose hinzufügen',
    en: 'Add power Socket',
    cz: 'Přidat zásuvku'
  },
  faucet: {
    fr: 'Robinet mitigeur',
    de: 'Mischbatterie',
    en: 'Mixing faucet',
    cz: 'Směšovací baterie'
  },
  faucetQuestion: {
    fr: 'Vous souhaitez ajouter un mitigeur ?',
    de: 'Möchten Sie eine Mischbatterie hinzufügen?',
    en: 'Do you want to add a mixing faucet?',
    cz: 'Chcete přidat směšovací baterii?'
  },
  dontAddFaucet: {
    fr: 'Ne pas ajouter de robinet mitigeur',
    de: 'Keine Mischbatterie hinzufügen',
    en: 'Do not add mixing faucet',
    cz: 'Nepřidávat směšovací baterii'
  },
  addFaucet: {
    fr: 'Ajouter un robinet mitigeur',
    de: 'Mischbatterie hinzufügen',
    en: 'Add mixing faucet',
    cz: 'Přidat směšovací baterii'
  },
  all: {
    fr: 'Tous',
    de: 'Alle',
    en: 'All',
    cz: 'Vše'
  },
  freeStanding: {
    fr: 'central',
    de: 'freistehend',
    en: 'free-standing',
    cz: 'volně stojící'
  },
  wallStanding: {
    fr: 'murale',
    de: 'wandstehend',
    en: 'wall-standing',
    cz: 'stojící u stěny'
  },
  singleRow: {
    fr: 'Une seule ligne',
    de: 'Einzeilig',
    en: 'Single row',
    cz: 'Jednořadý'
  },
  doubleRow: {
    fr: 'Double ligne ',
    de: 'Zweizeilige Anlage',
    en: 'Double row',
    cz: 'Dvouřadé zařízení'
  },
  doubleRowLabel: {
    fr: 'Double',
    de: 'Zweizeilig',
    en: 'Double',
    cz: 'Dvouřadý'
  },
  mknSocket: {
    fr: 'Socle MKN inox 150 mm',
    de: 'MKN Sockel 150 mm hoch',
    en: 'MKN plinth 150 mm',
    cz: 'Sokl MKN, výška 150 mm'
  },
  mknSocket200: {
    fr: 'Socle MKN inox 200 mm',
    de: 'MKN Sockel 200 mm hoch',
    en: 'MKN plinth 200 mm',
    cz: 'Sokl MKN, výška 200 mm'
  },
  buildSideSocket: {
    fr: 'Socle en béton de 150 mm',
    de: 'Bauseitiger Sockel 150 mm hoch',
    en: 'Concrete plinth with tiles 150 mm',
    cz: 'Sokl zajištěný zákazníkem, výška 150 mm'
  },
  buildSideSocket200: {
    fr: 'Socle en béton de 200 mm',
    de: 'Bauseitiger Sockel 200 mm hoch',
    en: 'Concrete plinth with tiles 200 mm',
    cz: 'Sokl zajištěný zákazníkem, výška 200 mm'
  },
  installedFeet: {
    fr: 'Posé sur les pieds 150 mm',
    de: 'Aufstellung Füße 150 mm hoch',
    en: 'Placed on feet 150 mm',
    cz: 'Instalace noh, výška 150 mm'
  },
  installedFeet200: {
    fr: 'Posé sur les pieds 200 mm',
    de: 'Aufstellung Füße 200 mm hoch',
    en: 'Placed on feet 200 mm',
    cz: 'Instalace noh, výška 200 mm'
  },
  searchDevices: {
    fr: 'Recherche de dispositifs',
    de: 'Gerät suchen',
    en: 'Search Device',
    cz: 'Vyhledat přístroj'
  },
  wingedDoor: {
    fr: 'Porte battante',
    de: 'Flügeltür',
    en: 'Wing door',
    cz: 'Křídlové dveře'
  },
  hygiene: {
    fr: 'hygiènique',
    de: 'Hygiene',
    en: 'Hygiene',
    cz: 'Hygiena'
  },
  angularRail: {
    fr: 'Rails GN',
    de: 'Winkelschiene',
    en: 'GN-rails',
    cz: 'Rohová lišta'
  },
  upperDrawer: {
    fr: 'Tiroir',
    de: 'Kastenschublade OB',
    en: 'Drawer',
    cz: 'Skříňová zásuvka – horní konstrukce'
  },
  lowerDrawer: {
    fr: 'Tiroir',
    de: 'Kastenschublade UB',
    en: 'Drawer',
    cz: 'Skříňová zásuvka – spodní konstrukce'
  },
  heatingCabinet: {
    fr: 'Armoire chauffante',
    de: 'Wärmeschrank',
    en: 'Heating Cabinet',
    cz: 'Termokomora'
  },
  upperPowerSocket: {
    fr: 'Prise',
    de: 'Steckdose OB',
    en: 'Socket',
    cz: 'Zásuvka – horní konstrukce'
  },
  mixingFaucet: {
    fr: 'Robinet mitigeur',
    de: 'Mischbatterie',
    en: 'Mixing Faucet',
    cz: 'Mischbatterien'
  },
  mixingFaucetMedia: {
    fr: 'Robinet mitigeur',
    de: 'Mischbatterie am Medienbord',
    en: 'Mixing Faucet',
    cz: 'Směšovací baterie na nástavbě pro média'
  },
  emptyingSystem: {
    fr: 'Robinet de vidange',
    de: 'Entleerungssystem',
    en: 'Emptying Faucet',
    cz: 'Vyprazdňovací systém'
  },
  eOven: {
    fr: 'Four électrique',
    de: 'E-Backofen',
    en: 'E-Oven',
    cz: 'Elektrická trouba'
  },
  gOven: {
    fr: 'Four Gaz',
    de: 'G-Backofen',
    en: 'G-Oven',
    cz: 'Plynová trouba'
  },
  close: {
    fr: 'Fermer',
    de: 'Schließen',
    en: 'Close',
    cz: 'Zavření'
  },
  cooling: {
    fr: 'Réfrigération',
    de: 'Kühlung',
    en: 'Refrigeration',
    cz: 'Chlazení'
  },
  coolingHint: {
    fr: 'Le réfrigérateur ne dispose pas de sa propre unité de réfrigération et doit être raccordé à un système de réfrigération sur place.',
    de: 'Das Kühlgerät besitzt kein eigenes Kälteaggregat und muss vor Ort an eine bauseitige Kälteanlage angeschlossen werden.',
    en: 'The fridge does not have its own refrigeration unit and must be connected to an refrigeration system on-site.',
    cz: 'The fridge does not have its own refrigeration unit and must be connected to an refrigeration system on-site.'
  },
  openSubstructure: {
    fr: 'Sous-structure ouverte',
    de: 'Durchgehend offener Unterbau',
    en: 'Open Substructure',
    cz: 'Průběžně otevřená spodní konstrukce'
  },
  wasteDrawer: {
    fr: 'Tiroir à déchets',
    de: 'Abfallschublade',
    en: 'Waste Drawer',
    cz: 'Zásuvka na odpad'
  },
  coldWaterInlet: {
    fr: "Arrivée d'eau froide",
    de: 'Kalt-Wasserzulauf',
    en: 'Cold Water Inlet',
    cz: 'Přítok studené vody'
  },
  handRail: {
    fr: 'Barre de maintien',
    de: 'Handlauf',
    en: 'Hand Rail',
    cz: 'Madlo'
  },
  heatingDrawer: {
    fr: 'Tiroir chauffant',
    de: 'Wärmeschublade',
    en: 'Heating Drawer',
    cz: 'Termozásuvka'
  },
  gNFrame: {
    fr: 'Cadre GN',
    de: 'GN-Gestell',
    en: 'GN Frame',
    cz: 'GN Frame'
  },
  warmingDrawer: {
    fr: 'Unité de maintient en T°',
    de: 'Warmhalteschublade',
    en: 'Warming unit',
    cz: 'Warming unit'
  },
  warmingDevice: {
    fr: 'Unité de maintient en T°',
    de: 'Warmhaltegerät',
    en: 'Warming unit',
    cz: 'Warming unit'
  },
  intermediatePlate: {
    fr: 'Etagère intermédiaire',
    de: 'Zwischenboden',
    en: 'Intermediate shelf',
    cz: 'Intermediate shelf'
  },
  none: {
    fr: 'Aucun',
    de: 'Nicht ausgewählt',
    en: 'None',
    cz: 'Nezvoleno'
  },
  pedestal: {
    fr: 'Socle',
    de: 'Sockel',
    en: 'Plinth',
    cz: 'Sokl'
  },
  sideBoard: {
    fr: 'Étagère latérale',
    de: 'Klappbord',
    en: 'Side Shelf',
    cz: 'Sklápěcí deska'
  },
  blockCut: {
    fr: 'Coupe de bloc (pour livraison en plusieurs pièces) ',
    de: 'Blockteilung',
    en: 'Block Cut',
    cz: 'Dělení bloků'
  },
  blockCutHint: {
    fr: "Au moment de la commande, il convient de vérifier et de confirmer la faisabilité technique des séparations de blocs telles qu'elles sont présentées ici.",
    de: 'Im Auftragsfall ist zu prüfen ob die Blockteilungen entsprechend der Darstellung ausgeführt werden können.',
    en: 'When the order is placed the technical feasibility of the block splittings as shown here needs to be checked and confirmed.',
    cz: 'V případě objednání je nutné zkontrolovat, jestli lze provést dělení bloků podle znázornění.'
  },
  attention: {
    fr: 'Attention',
    de: 'Hinweise',
    en: 'Attention',
    cz: 'Upozornění'
  },
  hintHeader: {
    fr: 'Note...',
    de: 'Planungshinweis',
    en: 'Note...',
    cz: 'Upozornění k plánování'
  },
  fryerHint: {
    fr: "Il convient de respecter une distance d'au moins 900 mm par rapport aux robinets d'eau ou aux unités fonctionnelles contenant des liquides. Nous recommandons également d'installer une protection contre les éclaboussures de la friteuse ou de limiter le rayon de pivotement du robinet d'eau.",
    de: 'Planungshinweis! Mindestens 900mm Abstand zu Wasserzapfstellen oder Geräten mit flüssigem Inhalt. Alternativ sind Fritteusen-Spritzschutz oder Schwenkblockierungen der Wasserzapfstelle einzuplanen.',
    en: 'Kindly keep at least 900 mm distance from water taps or functional units with liquid content. Alternatively we recommend a fryer splash guard or limiting the swivel radius of the water tap.',
    cz: 'Upozornění k plánování! Vzdálenost minimálně 900 mm od míst odběru vody nebo přístrojů s tekutým obsahem. Alternativně je nutné naplánovat ochranu proti stříkání z fritézy nebo otočné zábrany pro místo odběru vody.'
  },
  coverEnlargementHeader: {
    fr: 'Note...',
    de: 'Planungshinweis',
    en: 'Note...',
    cz: 'Upozornění k plánování'
  },
  coverEnlargementHint: {
    fr: 'Ce dispositif nécessite un agrandissement de la plaque supérieure de',
    de: 'Dieses Gerät benötigt einen hinteren Deckplatten-Überstand von ',
    en: 'This device needs a top plate enlargement of ',
    cz: 'Tento přístroj potřebuje zadní přesah krycích desek '
  },
  confirmDelete: {
    fr: 'Êtes-vous sûr de vouloir effacer ?',
    de: 'Wollen Sie wirklich löschen?',
    en: 'Are you sure you want to delete?',
    cz: 'Opravdu chcete smazat?'
  },
  confirmConfigDelete: {
    fr: 'Êtes-vous sûr de vouloir supprimer la configuration ?',
    de: 'Wollen Sie die Konfiguration wirklich löschen?',
    en: 'Are you sure you want to delete the configuration?',
    cz: 'Opravdu chcete smazat konfiguraci?'
  },
  delete: {
    fr: 'Supprimer',
    de: 'Löschen',
    en: 'Delete',
    cz: 'Smazat'
  },
  abort: {
    fr: 'Annuler',
    de: 'Abbrechen',
    en: 'Cancel',
    cz: 'Storno'
  },
  pickATable: {
    fr: 'Choisissez une table de travail.',
    de: 'Bitte wählen Sie einen Arbeitstisch aus.',
    en: 'Please select a work table.',
    cz: 'Vyberte pracovní stůl.'
  },
  pickADevice: {
    fr: 'Veuillez sélectionner un appareil fonctionnel.',
    de: 'Bitte wählen Sie ein Gerät aus.',
    en: 'Please select a functional appliance.',
    cz: 'Vyberte přístroj.'
  },
  noSubstructureActive: {
    fr: "Ce bloc n'a pas de sous-structure.",
    de: 'Dieser Block hat keinen Unterbau.',
    en: 'This block has no substructure.',
    cz: 'Tento blok nemá spodní konstrukci.'
  },
  noUpperDevices: {
    fr: "Il n'y a pas d'options de structure supérieure disponibles pour cet appareil.",
    de: 'Für dieses Gerät sind keine Oberbau Optionen verfügbar.',
    en: 'There are no available Upper Structure Options for this device.',
    cz: 'Pro tento přístroj nejsou k dispozici žádné možnosti pro horní konstrukci.'
  },
  border: {
    fr: "Bord supérieur de l'ouvrage",
    de: 'Abdeckungsabschluss',
    en: 'Work top edge',
    cz: 'Zakončení krytu'
  },
  sideCover: {
    fr: 'Couverture latérale',
    de: 'Seitenabdeckung',
    en: 'Side Cover',
    cz: 'Boční kryt'
  },
  steelKnob: {
    fr: 'Boutons en acier inoxydable',
    de: 'Edelstahlknebel',
    en: 'Stainless steel knobs',
    cz: 'Nerezová rukojeť'
  },
  selectDoor: {
    fr: 'Sélectionnez une porte.',
    de: 'Bitte selektieren Sie eine Tür.',
    en: 'Please select a door.',
    cz: 'Vyberte některé dveře.'
  },
  selectWindow: {
    fr: 'Sélectionnez une fenêtre.',
    de: 'Bitte selektieren Sie ein Fenster.',
    en: 'Please select a window.',
    cz: 'Vyberte některé okno.'
  },
  selectWall: {
    fr: 'Sélectionnez un mur.',
    de: 'Bitte selektieren Sie eine Wand.',
    en: 'Please select a wall.',
    cz: 'Vyberte některou stěnu.'
  },
  selectPillar: {
    fr: 'Sélectionnez un pilier.',
    de: 'Bitte selektieren Sie eine Säule.',
    en: 'Please select a Pillar.',
    cz: 'Vyberte některý sloup.'
  },
  horizontally: {
    fr: 'Horizontalement',
    de: 'Horizontal',
    en: 'Horizontally',
    cz: 'Horizontálně'
  },
  vertically: {
    fr: 'Verticalement',
    de: 'Vertikal',
    en: 'Vertically',
    cz: 'Vertikálně'
  },
  depth: {
    fr: 'Profondeur',
    de: 'Tiefe',
    en: 'Depth',
    cz: 'Hloubka'
  },
  rowDepth: {
    fr: 'Profondeur de la ligne (mm)',
    de: 'Reihentiefe (mm)',
    en: 'Row-Depth (mm)',
    cz: 'Hloubka řady (mm)'
  },
  salamanderHint: {
    fr: "La salamandre ne doit pas être placée au-dessus d'appareils thermiques ou à vapeur, car les limites de température et d'humidité seraient probablement dépassées.",
    de: 'Der Salamander sollte nicht über thermische oder dampfende Quellen platziert werden, weil die Grenzwerte von Temperatur und Feuchtigkeit voraussichtlich überschritten werden.',
    en: 'The salamander should not be placed over thermal or steaming devices, because the temperature and humidity limits would probably be exceeded. ',
    cz: 'Salamander by se neměl umisťovat nad zdroje uvolňující teplo a páru, protože pravděpodobně budou překročeny mezní hodnoty teploty a vlhkosti.'
  },
  gOvenHint: {
    fr: "Veuillez prévoir une zone d'installation d'au moins 400 mm à droite du four à gaz.",
    de: 'Bitte rechts neben dem Gasbackofen Montagebereich von mind. 400 mm vorsehen.',
    en: 'Please involve a installation area of at least 400 mm to the right of the gas oven.',
    cz: 'Vpravo vedle plynové trouby naplánujte prostor pro montáž min. 400 mm.'
  },
  removeColor: {
    fr: 'Retirer la couleur',
    de: 'Farbe entfernen',
    en: 'Remove color',
    cz: 'Odstranit barvu'
  },
  saveAs: {
    fr: 'Enregistrer sous',
    de: 'Speichern unter',
    en: 'Save as',
    cz: 'Uložit jako'
  },
  emailSent: {
    fr: 'Mails envoyés.',
    de: 'E-Mails gesendet.',
    en: 'Mails sent.',
    cz: 'E-maily odeslány.'
  },
  emailSendError: {
    fr: "Les mails n'ont pas pu être envoyés",
    de: 'E-Mails konnten nicht gesendet werden.',
    en: 'Mails could not be sent',
    cz: 'E-maily nebylo možné odeslat.'
  },
  mail: {
    fr: 'e-mail',
    de: 'E-Mail',
    en: 'Mail',
    cz: 'E-mail'
  },
  configPublished: {
    fr: 'Publier la configuration',
    de: 'Konfiguration veröffentlichen',
    en: 'Publish Configuration',
    cz: 'Zveřejnit konfiguraci'
  },
  configPublishError: {
    fr: "La configuration n'a pas pu être publiée.",
    de: 'Konfiguration konnte nicht veröffentlicht werden.',
    en: 'Configuration could not be published.',
    cz: 'Konfigurace nemohla být zveřejněna.'
  },
  newPassword: {
    fr: 'Nouveau mot de passe',
    de: 'Neues Passwort',
    en: 'New password',
    cz: 'Nové heslo'
  },
  confirmPassword: {
    fr: 'Confirmer le nouveau mot de passe',
    de: 'Neues Passwort bestätigen',
    en: 'Confirm new password',
    cz: 'Potvrdit nové heslo'
  },
  notFound: {
    fr: 'Échec du chargement',
    de: 'Laden fehlgeschlagen',
    en: 'Loading failed',
    cz: 'Nahrávání se nezdařilo'
  },
  notFoundDesc: {
    fr: "La configuration n'a pas pu être chargée. Si vous n'avez pas créé la configuration, assurez-vous qu'elle est publique.",
    de: 'Die Konfiguration konnte nicht geladen werden. Falls Sie nicht der Ersteller der Konfiguration sind stellen Sie sicher, dass die Konfiguration öffentlich ist.',
    en: 'Configuration could not be loaded. If you did not create the configuration please make sure it is public.',
    cz: 'Konfigurace nemohla být nahrána. Pokud nejste autorem konfigurace, zajistěte, aby konfigurace byla veřejná.'
  },
  toMenu: {
    fr: 'Retour au menu',
    de: 'Zum Hauptmenü',
    en: 'Back to menu',
    cz: 'K hlavnímu menu'
  },
  addMasterline: {
    fr: 'Ajouter Masterline',
    de: 'Masterline hinzufügen',
    en: 'Add Masterline',
    cz: 'Přidat Masterline'
  },
  addMarineMeister: {
    fr: 'Ajouter une configuration marine',
    de: 'Marinemeister hinzufügen',
    en: 'Add Marinemeister',
    cz: 'Přidat Marinemeister'
  },
  marineMeister: {
    fr: 'Configuration marine',
    de: 'Marinemeister',
    en: 'Marinemeister',
    cz: 'Marinemeister'
  },
  modular: {
    fr: 'Modulaire',
    de: 'Modular',
    en: 'Modular',
    cz: 'Modulární'
  },
  modularNOL: {
    fr: 'NOL',
    de: 'NOL',
    en: 'NOL',
    cz: 'NOL'
  },
  masterline: {
    fr: 'Masterline',
    de: 'Masterline',
    en: 'Masterline',
    cz: 'Masterline'
  },
  flexichef: {
    fr: 'Flexichef',
    de: 'Flexichef',
    en: 'Flexichef',
    cz: 'Flexichef'
  },
  spacekombi: {
    fr: 'Flexicombi / Spacecombi',
    de: 'Kombidämpfer',
    en: 'Combi Steamer',
    cz: 'Konvektomat'
  },
  publicConfiguration: {
    fr: 'La configuration est publique',
    de: 'Ihre Konfiguration ist öffentlich',
    en: 'Configuration is public',
    cz: 'Vaše konfigurace je veřejná'
  },
  secretConfiguration: {
    fr: "La configuration n'est pas publique",
    de: 'Ihre Konfiguration ist nicht öffentlich',
    en: 'Configuration is not public',
    cz: 'Vaše konfigurace není veřejná'
  },
  removedPublish: {
    fr: 'La configuration est réglée sur privé',
    de: 'Konfiguration privat gesetzt',
    en: 'Configuration set to private',
    cz: 'Konfigurace nastavena jako soukromá'
  },
  addModular: {
    fr: 'Ajouter un bloc modulaire',
    de: 'Modularblock einfügen',
    en: 'Add Modular Block',
    cz: 'Vložit modulární blok'
  },
  resetSent: {
    fr: 'Courrier envoyé.',
    de: 'E-Mail gesendet.',
    en: 'Mail sent.',
    cz: 'E-mail odeslán.'
  },
  resetNotSent: {
    fr: "Le courrier n'a pas été envoyé.",
    de: 'E-Mail nicht gesendet.',
    en: 'Mail not sent.',
    cz: 'E-mail nebyl odeslán.'
  },
  passwordChanged: {
    fr: 'Le mot de passe a été modifié.',
    de: 'Passwort geändert.',
    en: 'Password changed.',
    cz: 'Heslo změněno.'
  },
  passwordChangeError: {
    fr: "Le mot de passe n'a pas pu être modifié.",
    de: 'Passwort konnte nicht geändert werden.',
    en: 'Password could not be changed.',
    cz: 'Heslo nebylo možné změnit.'
  },
  installationWallDepth: {
    fr: "Profondeur du mur d'installation",
    de: 'Tiefe Installationswand',
    en: 'Installation Wall Depth',
    cz: 'Hloubka instalační stěny'
  },
  installationWallHeight: {
    fr: "Hauteur du mur d'installation",
    de: 'Höhe Installationswand',
    en: 'Installation Wall Height',
    cz: 'Výška Instalační stěna'
  },
  installationWall: {
    fr: "Mur d'installation",
    de: 'Installationswand',
    en: 'Installation Wall',
    cz: 'Instalační stěna'
  },
  addUnderframe: {
    fr: 'Ajouter un piètement',
    de: 'Untergestell hinzufügen',
    en: 'Add Underframe',
    cz: 'Přidat podstavec'
  },
  steam: {
    fr: 'Vapeur',
    de: 'Dampf',
    en: 'Steam',
    cz: 'Pára'
  },
  gas: {
    fr: 'Gaz',
    de: 'Gas',
    en: 'Gas',
    cz: 'Plyn'
  },
  blockDescription: {
    fr: 'Description du bloc',
    de: 'Blockbeschreibung',
    en: 'Block Description',
    cz: 'Popis bloku'
  },
  coverEnlargement: {
    fr: 'extension latérale',
    de: 'Abdeckungsverbreiterung',
    en: 'Top plate enlargement',
    cz: 'Rozšíření krytu'
  },
  doorColor: {
    fr: 'Sous-structures colorées',
    de: 'Farbige Unterbauten',
    en: 'Colored Substructures',
    cz: 'Barevné spodní konstrukce'
  },
  doubleSidedOperation: {
    fr: 'commandes sur les deux faces',
    de: 'Beidseitige Bedienung',
    en: 'Double Sided Operation',
    cz: 'Oboustranné ovládání'
  },
  freeStandingOptions: {
    fr: 'Options en pose libre',
    de: 'Freistehende Optionen',
    en: 'Free Standing Options',
    cz: 'Volně stojící možnosti'
  },
  salamanderBoard: {
    fr: 'Tablette Salamande',
    de: 'Salamander Board',
    en: 'Salamander Shelf',
    cz: 'Nástavba Salamander'
  },
  middleOptions: {
    fr: 'Options centrales',
    de: 'Mittlere Optionen',
    en: 'Middle Options',
    cz: 'Prostřední možnosti'
  },
  middleMixingFaucet: {
    fr: 'Mitigeur sur axe central',
    de: 'Mischbatterie auf Mittenachse',
    en: 'Mixing Faucet on Central Axis',
    cz: 'Směšovací baterie na středové ose'
  },
  dimSumHint: {
    fr: "Il n'est pas possible d'installer une seule rangée en pose libre, y compris le four à vapeur électrique.",
    de: 'Einzeilig freistehende MasterLine Anlage mit DimSum-Steamer nicht möglich.',
    en: 'Single row free standing incl. electric DimSum steamer not possible.',
    cz: 'Jednořadé volně stojící zařízení MasterLine s dim sum napařovačem není možné.'
  },
  flexiMasterlineHint: {
    fr: 'Raccordement et étanchéité par des barres de fixation spéciales',
    de: 'Verbindung und Abdichtung mittels aufgesteckten Spezialklemmstegen',
    en: 'Connection and sealing by special clip bars',
    cz: 'Connection and sealing by special clip bars'
  },
  qrText: {
    fr: 'Cliquez ici pour visualiser votre configuration en 3D',
    de: 'Hier geht es zu ihrem 3-D Modell',
    en: 'Click here to view your configuration in 3D',
    cz: 'Zde přejdete na váš 3D model'
  },
  blindCover: {
    fr: 'Couverture ',
    de: 'Blindblende',
    en: 'Blind Cover',
    cz: 'Zaslepovací clona'
  },
  showConfigs: {
    fr: 'Afficher les configurations',
    de: 'Konfigurationen anzeigen',
    en: 'Show configurations',
    cz: 'Zobrazit konfigurace'
  },
  hideConfigs: {
    fr: 'Masquer les configurations',
    de: 'Konfigurationen ausblenden',
    en: 'Hide configurations',
    cz: 'Skrýt konfigurace'
  },
  installationHeight: {
    fr: "Hauteur d'installation",
    de: 'Aufbauhöhe',
    en: 'Installation Height',
    cz: 'Výška konstrukce'
  },
  baseCover: {
    fr: 'Couvercle du socle',
    de: 'Sockelabdeckboden',
    en: 'Base Cover',
    cz: 'Krycí police soklu'
  },
  readyXPress: {
    fr: 'ReadyXpress Cuisson rapide',
    de: 'ReadyXpress Highspeed Garen',
    en: 'ReadyXpress Highspeed Cooking',
    cz: 'Tepelná úprava pokrmů ReadyXPress Highspeed'
  },
  spaceClean: {
    fr: 'SpaceClean',
    de: 'SpaceClean',
    en: 'SpaceClean',
    cz: 'SpaceClean'
  },
  flexiCombiAir: {
    fr: 'FlexiCombi Air',
    de: 'FlexiCombi Air',
    en: 'FlexiCombi Air',
    cz: 'FlexiCombi Air'
  },
  flexiCombiDoor: {
    fr: 'Changer le côté de la poignée',
    de: 'Griffseite wechseln',
    en: 'Change handle side',
    cz: 'Vyměňte stranu rukojeti'
  },
  magicHood: {
    fr: 'Magic Hood',
    de: 'Magic Hood Haube',
    en: 'Magic Hood',
    cz: 'Kryt MagicHood'
  },
  marginRoundedHint: {
    fr: 'La valeur saisie a été arrondie pour des raisons techniques.',
    de: 'Aus technischen Gründen wurde Ihr Eingabewert gerundet.',
    en: 'The entered value has been rounded for technical reasons.',
    cz: 'The entered value has been rounded for technical reasons.'
  },
  createUser: {
    fr: 'Créer un utilisateur',
    de: 'Nutzer erstellen',
    en: 'Create User',
    cz: 'Vytvořit uživatele'
  },
  editUser: {
    fr: "Modifier l'utilisateur",
    de: 'Nutzer bearbeiten',
    en: 'Edit User',
    cz: 'Upravit uživatele'
  },
  rights: {
    fr: 'Autorisations',
    de: 'Berechtigungen',
    en: 'Rights',
    cz: 'Oprávnění'
  },
  activateUser: {
    fr: "Activer l'utilisateur",
    de: 'Nutzer aktiv',
    en: 'Activate User',
    cz: 'Odemknout uživatele'
  },
  access: {
    fr: 'Accès',
    de: 'Zugriff',
    en: 'Access',
    cz: 'Přístup'
  },
  upstand: {
    fr: 'Rehaussement',
    de: 'Aufkantung',
    en: 'Upstand',
    cz: 'Vstát'
  },
  voltage: {
    fr: 'Tension',
    de: 'Spannung',
    en: 'Voltage',
    cz: 'Napětí'
  },
  autoHygiene: {
    de: 'Unterbauten in Hygiene H2',
    fr: 'Sous-structures en Hygiène H2',
    en: 'Substructures in Hygiene H2',
    cz: 'Spodní konstrukce v provedení Hygiene H2'
  },
  variants: {
    de: 'Gerätevarianten',
    fr: 'Variantes d’appareils',
    en: 'Device variants',
    cz: 'Varianty přístrojů'
  }
};

export default LMenu;
