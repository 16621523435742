import Block from 'page/Editor/configuration/Block';
import { Action } from '../index';
import { useContext, useReducer } from 'react';
import { EditorContext } from 'context';
import { MasterlineTypes } from 'page/Editor/Menu/Menus/Masterline/Partials';
import { useGetHygieneDevices } from 'page/Editor/Menu/Menus/Masterline/Partials/Structure/hooks/useGetHygieneDevices';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';

export const useMasterlineReducer = (
  ROWS: MasterlineTypes,
  setSelectedRow: React.Dispatch<React.SetStateAction<string>>,
  setSelectedSeries: React.Dispatch<React.SetStateAction<string>>
) => {
  const { configuration, setHasMasterline, setSelectedMasterline, setCanAdvanceMasterline } = useContext(EditorContext);
  const initialMasterline: Block = configuration.getMasterline()[0];
  const { isLoading: isLoadingHygieneDevices, hygieneDevices } = useGetHygieneDevices();

  const addMasterline = () => {
    const newBlock = new Block({ blockType: 'Masterline' });
    newBlock.setId('MasterlineBlock-' + (configuration.getMasterline() ? configuration.getMasterline().length + 1 : 1));
    setSelectedRow(ROWS.singleWall);
    setSelectedSeries('850');

    newBlock.setShowLabels(configuration.isShowLabels());
    newBlock.setShowCutLabels(configuration.isShowCutLabels());
    newBlock.setShowConnections(configuration.isShowConnections());

    dispatch({ type: 'addBlock', payload: newBlock });
    const masterlines = configuration.getMasterline();
    configuration.getMasterline().push(newBlock);
    newBlock?.getNode()?.getMark()?.setEnabled(true);
    if (Array.isArray(masterlines) && masterlines.length) {
      setSelectedMasterline(configuration.getMasterline()[masterlines.length - 1]);
      setHasMasterline(true);
      setCanAdvanceMasterline(true);
    }
  };

  const masterlineReducer = (state: Block, action: Action) => {
    switch (action.type) {
      case 'rowChange':
        if (action.payload === ROWS.singleWall) {
          if (state.getType() === 'Double') {
            state.setType('Single');
          }
          state.setSingleType('Wall');
        } else if (action.payload === ROWS.singleFree) {
          if (state.getType() === 'Double') {
            state.setType('Single');
          }
          state.setSingleType('Free');
        } else if (action.payload === ROWS.double) {
          if (!(state.getType() === 'Double')) {
            state.setType('Double');
          }
        }
        return state;
      case 'depthChange':
        const topRow = state.getRowTop();
        const bottomRow = state.getRowBottom();
        if (state.getType() === 'Double') {
          if (action.payload === '700 / 700') {
            topRow.setDepth(700);
            bottomRow.setDepth(700);
            //setSeriesFilter('700');
          } else if (action.payload === '850 / 700') {
            topRow.setDepth(850);
            bottomRow.setDepth(700);
            //setSeriesFilter('all');
          } else if (action.payload === '700 / 850') {
            topRow.setDepth(700);
            bottomRow.setDepth(850);
            //setSeriesFilter('all');
          } else if (action.payload === '850 / 850') {
            topRow.setDepth(850);
            bottomRow.setDepth(850);
            //setSeriesFilter('850');
          }
        } else {
          if (action.payload === '700' || action.payload === '740') {
            bottomRow.setDepth(700);
            //setSeriesFilter('700');
          } else {
            bottomRow.setDepth(850);
            //setSeriesFilter('850');
          }
        }
        return state;

      case 'coverEnlargementChange':
        // TODO: Adapt Block
        if (typeof action.payload === 'string') {
          state.setDepthExtension(parseInt(action.payload));
        }
        return state;
      case 'addBlock':
        if (action.payload instanceof Block) {
          return action.payload;
        }
        return state;
      case 'changeSelectedBlock':
        if (action.payload instanceof Block) {
          return action.payload;
        }
        return state;
      case 'changeUpperStructureOnly':
        if (typeof action.payload === 'boolean') {
          state.setUpperstrcutureOnly(action.payload);
        }
        return state;
      case 'changeDescription':
        if (typeof action.payload === 'string') {
          state.setDescription(action.payload);
        } else {
          state.setDescription('');
        }
        return state;
      case 'changeAutoHygiene':
        state.setAutoHygiene(Boolean(action.payload));

        const topRowItems = state.getRowTop()?.getItems();
        const topRowDepth = state.getRowTop()?.getDepth();
        if (topRowItems) {
          topRowItems.forEach(item => {
            if (action.payload) {
              if (EquipmentHelper.canHaveHygiene(item)) {
                const hygieneDevice = hygieneDevices.filter(({ model }) => model.depths.includes(topRowDepth)).find(hD => item.getWidth() === hD.model.width);
                if (hygieneDevice) {
                  EquipmentHelper.setHygiene(item, hygieneDevice);
                }
              }
            } else {
              EquipmentHelper.setHygiene(item, null);
            }
          });
        }

        const bottomRowItems = state.getRowBottom()?.getItems();
        const bottomRowDepth = state.getRowBottom()?.getDepth();
        if (bottomRowItems) {
          bottomRowItems.forEach(item => {
            if (EquipmentHelper.canHaveHygiene(item)) {
              if (action.payload) {
                const hygieneDevice = hygieneDevices
                  .filter(({ model }) => model.depths.includes(bottomRowDepth))
                  .find(hD => item.getWidth() === hD.model.width);
                if (hygieneDevice) {
                  EquipmentHelper.setHygiene(item, hygieneDevice);
                }
              } else {
                EquipmentHelper.setHygiene(item, null);
              }
            }
          });
        }

        return state;

      default:
        return state;
    }
  };
  const [masterlineBlock, dispatch] = useReducer(masterlineReducer, initialMasterline);

  return {
    isLoadingHygieneDevices,
    masterlineBlock,
    dispatch,
    addMasterline
  };
};
