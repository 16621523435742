import { useContext, useEffect, useState } from 'react';
import { EditorContext } from 'context/editor-context';
import BlockItem from 'page/Editor/configuration/BlockItem';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import { isSubtype, post } from 'utils';
import { Device, Subtype } from 'types';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import Localizations from 'languages';
import { LangContext } from 'context';

export const useHandleSelection = () => {
  const { lang, langId } = useContext(LangContext);

  const existingSubtypes = ['UpperDrawer', 'UpperPowerSocket', 'MixingFaucet', 'ColdWaterInlet', 'DoubleSidedOperation'];
  const [availableSubtypes, setAvailableSubtypes] = useState<Subtype[]>([]);
  const [availableDevices, setAvailableDevices] = useState<Device[]>([]);
  const [mergeMode, setMergeMode] = useState(false);
  const [selectionType, setSelectionType] = useState<'BlockItem' | 'BlockGroup'>(null);
  const [selectedUpperPowerSocket, setSelectedUpperPowerSocket] = useState(Localizations['none'][lang]);
  const [selectedUpperPowerSocket_Second, setSelectedUpperPowerSocket_Second] = useState(Localizations['none'][lang]);
  const [usedDevices, setUsedDevices] = useState({
    UpperDrawer: false,
    MixingFaucet: false,
    ColdWaterInlet: false,
    DoubleSidedOperation: false
  });

  const getAvailableSockets = () => {
    return availableDevices.filter(d => isSubtype(d.subtype, ['UpperPowerSocket'])).map(d => d.translations[langId]?.name);
  };
  const availableSockets = getAvailableSockets();

  const { selection } = useContext(EditorContext);

  useEffect(() => {
    const width = selection instanceof BlockItem ? selection.getWidth() : selection instanceof BlockGroup ? selection.getWidth() : 0;
    const depth = selection instanceof BlockItem ? selection.getBlockRow().getDepth() : selection instanceof BlockGroup ? selection.getParent().getDepth() : 0;
    const checkAvailableSubtypes = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
        data: {
          type: 'masterline',
          width: width,
          depth: depth,
          subtypes: existingSubtypes
        }
      });
      if (data) {
        // Subtypes
        const tempAvailableSubtypes: Subtype[] = [];
        const tempAvailableDevices: Device[] = [];
        data.forEach((result: Device) => {
          // @ts-ignore
          if (!tempAvailableSubtypes.includes(result.subtype) && selection.compatibleWithEquipment(Subtype[result.subtype])) {
            //@ts-ignore
            tempAvailableSubtypes.push(Subtype[result.subtype]);
            tempAvailableDevices.push(result);
          }
        });

        setAvailableDevices(tempAvailableDevices);
        setAvailableSubtypes(tempAvailableSubtypes);
      }
      if (error) {
        console.log(error);
      }
    };

    if (!selection) {
      setMergeMode(false);
      setSelectionType(null);
    } else {
      if (selection instanceof BlockItem) {
        setSelectionType('BlockItem');
        checkAvailableSubtypes();
        setUsedDevices({
          UpperDrawer: EquipmentHelper.getUpperDrawer(selection) ? true : false,
          /* UpperPowerSocket: EquipmentHelper.getUpperPowerSocket(selection) ? true : false, */
          MixingFaucet: EquipmentHelper.getMixingFaucet(selection) ? true : false,
          ColdWaterInlet: EquipmentHelper.getColdWaterInlet(selection) ? true : false,
          DoubleSidedOperation: EquipmentHelper.getDoubleSidedOperation(selection) ? true : false
        });
        const firstSocket = EquipmentHelper.getUpperPowerSocket(selection);
        setSelectedUpperPowerSocket(firstSocket ? firstSocket.getDeviceObject()?.translations[langId]?.name : Localizations['none'][lang]);
        const secondSocket = EquipmentHelper.getUpperPowerSocket(selection, 1);
        setSelectedUpperPowerSocket_Second(secondSocket ? secondSocket.getDeviceObject()?.translations[langId]?.name : Localizations['none'][lang]);
      }
    }
  }, [selection]);

  return {
    availableSubtypes,
    availableSockets,
    availableDevices,
    mergeMode,
    selectionType,
    usedDevices,
    setUsedDevices,
    selectedUpperPowerSocket,
    setSelectedUpperPowerSocket,
    selectedUpperPowerSocket_Second,
    setSelectedUpperPowerSocket_Second
  };
};
