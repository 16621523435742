import { post } from './AJAX';

export const getCategories = async (type = 'masterline', voltage?: number, isUpperStructureOnly?: boolean) => {
  const SESSION_KEY = `categories-${type}-temp-${voltage}-${isUpperStructureOnly}`;
  const fetchCategories = async () => {
    const cachedCategories = sessionStorage.getItem(SESSION_KEY);
    if (!cachedCategories) {
      try {
        const { data, error, count } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/categories`, {
          data: {
            type,
            voltage
          }
        });
        if (data) {
          sessionStorage.setItem(SESSION_KEY, JSON.stringify({ data, error, count }));
        }
        return { data, error, count };
      } catch (error) {
        return {
          data: null,
          error,
          count: 0
        };
      }
    } else {
      return JSON.parse(cachedCategories);
    }
  };
  const { data, error, count } = await fetchCategories();

  return { count, data, error };
};
