import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';

// Atoms
import { Button, Checkbox, Icon } from 'atoms';

// Context
import { EditorContext, LangContext } from 'context';

// Components
import Vector3 from '../../../configuration/Vector3';
import BlockItem from '../../../configuration/BlockItem';
import { EquipmentHelper } from '../../../configuration/Equipment';
import FreeItem from '../../../configuration/FreeItem';
import BlockGroup from 'page/Editor/configuration/BlockGroup';

// Lang
import Localizations from 'languages';

// Types
import { Device, Subtype } from 'types';

// Styles
import './style.scss';

// Utils
import { addDevice, addFreeDevice, canHaveSubstructures, canHaveUpperstructures, findMatchingUnderframe, get, post } from 'utils';
import Dimensions from 'utils/Dimensions';

import { useExternalDevice } from './useExternalDevice';
import { useYEnhancement } from 'page/Editor/Menu/Shared/DeviceDetailsSmall/hooks/useYEnhancement';
import { FlexiChefOptions, SpaceCombiOptions, VariantSelection } from './Partials';
import { useWidthSelection } from 'page/Editor/Menu/Shared/DeviceDetailsSmall/hooks';
import { isSubstructureClashing } from 'utils/isSubstructureClashing';

interface Props {
  device: Device;
  showTrashcan?: boolean;
  showUnderframe?: boolean;
}

export const DeviceDetailsSmall: FC<Props> = ({ device, showTrashcan = false, showUnderframe = false }) => {
  const {
    addImageToStorage,
    configuration,
    menuView,
    deviceImageStorage,
    setCanAdvanceMasterline,
    setCanAdvanceModular,
    setCanAdvanceModularNOL,
    setCoverEnlargementSize,
    setMasterlineView,
    setModularView,
    setModularNOLView,
    scene,
    selection,
    selectedMasterline,
    selectedModular,
    seriesFilter,
    setSelection,
    setDialog,
    setErrorAlert,
    setFlexiMenuView,
    setMarineMeisterView,
    selectedMarineMeister,
    selectedModularNOL
  } = useContext(EditorContext);

  const { isExternal, externalDepth, externalHeight, externalWidth, setExternalDepth, setExternalHeight, setExternalWidth } = useExternalDevice();
  const usedBlock =
    menuView === 'modular'
      ? selectedModular
      : menuView === 'modularNOL'
      ? selectedModularNOL
      : menuView === 'marineMeister'
      ? selectedMarineMeister
      : selectedMasterline;

  const { langId, lang } = useContext(LangContext);

  const { name, description } = device.translations[langId];
  const { elektro, electroConditions, gas, voltage, steam } = device.energy;
  const { equipmentCombinations } = device.dependency;
  const [image, setImage] = useState(null);
  const [availableUnderframe, setAvailableUnderframe] = useState(null);
  const [hasUnderframe, setHasUnderframe] = useState(
    showUnderframe
      ? selection &&
          selection instanceof FreeItem &&
          (EquipmentHelper.getUnderframe580(selection) !== null || EquipmentHelper.getUnderframe850(selection) !== null)
      : false
  );

  const { yEnhancement, setYEnhancement } = useYEnhancement(selection instanceof FreeItem ? selection : null);

  const { width, widthMax, widthMin, setWidth, showWidthSelection, validateWidth } = useWidthSelection(device, selection);

  const [itemAddable, setItemAddable] = useState(
    device && device.model && (device.model.spaceCombi || device.model.flexiChef)
      ? true
      : usedBlock
      ? !isSubstructureClashing(device, usedBlock) &&
        (usedBlock.canItemBeDropped(device, usedBlock.getRowTop()) || usedBlock.canItemBeDropped(device, usedBlock.getRowBottom()))
      : false
  );
  const [isSelectedInScene, setIsSelectedInScene] = useState(false);
  const [mounted, setMounted] = useState(false);

  const urlCreator = window.URL || window.webkitURL;

  useEffect(() => {
    const getImage = async () => {
      const imgUrl = `${process.env.REACT_APP_API_URL}/device/get/${device.id}/model/image`;
      const { data, error } = await get(imgUrl, { responseType: 'blob' });
      if (data) {
        setImage(urlCreator.createObjectURL(data));
        addImageToStorage({ id: device.id, blob: data });
      }
      if (error) {
        console.error(error);
      }
    };

    // Check if image is in Context, else fetch from DB
    if (isExternal !== null && !isExternal) {
      if (deviceImageStorage && deviceImageStorage[device.id]) {
        setImage(urlCreator.createObjectURL(deviceImageStorage[device.id]));
      } else {
        getImage();
      }
    }
    return () => {
      setMounted(false);
    };
  }, [device?.id, deviceImageStorage, isExternal]);

  useEffect(() => {
    if (selection === scene.getSelected() && !(selection instanceof BlockGroup) && mounted) {
      setIsSelectedInScene(true);
    }
  }, [selection, scene]);

  useEffect(() => {
    const getUnderframe = async () => {
      //@ts-ignore
      const is850 = equipmentCombinations.includes('Underframe850');
      //@ts-ignore
      const is580 = equipmentCombinations.includes('Underframe580');
      const type = is850 ? [Subtype.Underframe850] : is580 ? [Subtype.Underframe580] : [];
      const depth = device.model.depths[0];
      if (type && type.length > 0) {
        const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
          data: {
            depth,
            subtypes: type
          }
        });
        if (data && Array.isArray(data) && data.length > 0) {
          const matchingUnderframe = findMatchingUnderframe(data, device);
          setAvailableUnderframe(matchingUnderframe);
        }
        if (error) {
          console.error(error);
        }
      }
    };

    if (isExternal !== null && !isExternal) {
      if (!mounted) {
        getUnderframe();
        setMounted(true);
      } else {
        if (showUnderframe) {
          if (hasUnderframe) {
            //@ts-ignore
            if (equipmentCombinations.includes('Underframe850')) {
              //@ts-ignore
              EquipmentHelper.setUnderframe850(selection, availableUnderframe);
              //@ts-ignore
            } else if (equipmentCombinations.includes('Underframe580')) {
              //@ts-ignore
              EquipmentHelper.setUnderframe580(selection, availableUnderframe);
            }
          } else {
            if (selection instanceof FreeItem) {
              EquipmentHelper.setUnderframe850(selection, null);
              EquipmentHelper.setUnderframe580(selection, null);
            }
          }
        }
      }
    }
  }, [hasUnderframe, availableUnderframe, isExternal]);

  return (
    <div className="DeviceDetails-Small-Wrapper">
      <div className="DeviceDetails-Small">
        <div className="DeviceDetails-Small-Content">
          {!isExternal && (
            <div className="DeviceDetails-Small-Content-Image" key={`${image}`}>
              <img src={image} alt="" />
            </div>
          )}

          <div className="DeviceDetails-Small-Content-Title">{name}</div>
          <div className="DeviceDetails-Small-Content-Description">{description}</div>
          {showWidthSelection && (
            <div className="Submenu-Attribute">
              <label className="Submenu-Label">
                <input
                  className="Submenu-Input"
                  min={widthMin}
                  max={widthMax}
                  onChange={event => {
                    setWidth(Number(event.target.value));
                  }}
                  onBlur={event => {
                    validateWidth();
                  }}
                  step={menuView === 'masterline' ? 100 : 10}
                  type="number"
                  value={width}
                />
                {Localizations['width'][lang]} (mm)
              </label>
            </div>
          )}
          {!isExternal && !showWidthSelection && (
            <div className="DeviceDetails-Small-Content-Dimensions">
              {device.model.depths.map((d, index) => (
                <div key={`device-depth-${index}`}>{`${device.model.width} x ${d} x ${device.model.height} mm`}</div>
              ))}
            </div>
          )}
          {isExternal && (
            <>
              <div className="Submenu-Attribute">
                <label className="Submenu-Label">
                  <input
                    className="Submenu-Input"
                    min={100}
                    onChange={event => {
                      setExternalWidth(Number(event.target.value));
                    }}
                    onBlur={event => {
                      if (Number(event.target.value) < Number(event.target.min)) {
                        setExternalWidth(100);
                      }
                    }}
                    step={100}
                    type="number"
                    value={externalWidth}
                  />
                  {Localizations['width'][lang]} (mm)
                </label>
              </div>
              <div className="Submenu-Attribute">
                <label className="Submenu-Label">
                  <input
                    className="Submenu-Input"
                    min={100}
                    onChange={event => {
                      const val = parseInt(event.target.value);
                      setExternalDepth(val);
                    }}
                    onBlur={event => {
                      if (Number(event.target.value) < Number(event.target.min)) {
                        setExternalDepth(100);
                      }
                    }}
                    step={100}
                    type="number"
                    value={externalDepth}
                  />
                  {Localizations['depth'][lang]} (mm)
                </label>
              </div>
              <div className="Submenu-Attribute">
                <label className="Submenu-Label">
                  <input
                    className="Submenu-Input"
                    min={100}
                    onChange={event => {
                      const val = parseInt(event.target.value);
                      setExternalHeight(val);
                    }}
                    onBlur={event => {
                      if (Number(event.target.value) < Number(event.target.min)) {
                        setExternalHeight(100);
                      }
                    }}
                    step={100}
                    type="number"
                    value={externalHeight}
                  />
                  {Localizations['height'][lang]} (mm)
                </label>
              </div>
            </>
          )}

          <div className="DeviceDetails-Small-Content-Details">
            {elektro && elektro > 0 ? (
              <>
                <span>{`${elektro} kW`}</span> <br />
              </>
            ) : (
              <></>
            )}
            {electroConditions && (
              <>
                <span>{electroConditions}</span> <br />
              </>
            )}
            {voltage && voltage > 0 ? (
              <>
                <span>{`${voltage} V`}</span>
                <br />
              </>
            ) : (
              <></>
            )}
            {steam && steam > 0 ? (
              <>
                <span>{`${Localizations['steam'][lang]}${steam} kW`}</span>
                <br />
              </>
            ) : (
              <></>
            )}
            {gas && gas > 0 ? (
              <>
                <span>{`${Localizations['gas'][lang]}: ${gas} kW`}</span>
              </>
            ) : (
              <></>
            )}
          </div>
          {!(scene.getSelected() === selection) && (
            <Button
              btnType="third"
              disabled={!itemAddable}
              fontSize=".6rem"
              onClick={() => {
                if (device.model.modular && menuView === 'modular') {
                  addDevice(scene, device, setDialog, setErrorAlert, setCoverEnlargementSize, seriesFilter, selectedModular);
                  setCanAdvanceModular(true);
                } else if (device.model.modularNOL && menuView === 'modularNOL') {
                  addDevice(scene, device, setDialog, setErrorAlert, setCoverEnlargementSize, seriesFilter, selectedModularNOL, true);
                  setCanAdvanceModularNOL(true);
                } else {
                  if (menuView !== 'masterline' && device.model && (device.model.flexiChef || device.model.spaceCombi)) {
                    addFreeDevice(scene, device, configuration);
                  } else {
                    addDevice(scene, device, setDialog, setErrorAlert, setCoverEnlargementSize, seriesFilter, usedBlock);
                    //selectDevice(device);
                    if (menuView === 'masterline') {
                      if (device.model.flexiChef) {
                        setErrorAlert(['flexiMasterlineHint']);
                      }
                      setCanAdvanceMasterline(true);
                    }
                  }
                }

                setItemAddable(
                  device && device.model && (device.model.spaceCombi || device.model.flexiChef)
                    ? true
                    : menuView === 'masterline' && usedBlock
                    ? !isSubstructureClashing(device, selectedMasterline) &&
                      (selectedMasterline.canItemBeDropped(device, usedBlock.getRowTop()) ||
                        selectedMasterline.canItemBeDropped(device, usedBlock.getRowBottom()))
                    : menuView === 'modular' && selectedModular
                    ? selectedModular.canItemBeDropped(device, selectedModular.getRowTop()) ||
                      selectedModular.canItemBeDropped(device, selectedModular.getRowBottom())
                    : menuView === 'modularNOL' && selectedModularNOL
                    ? selectedModularNOL.canItemBeDropped(device, selectedModularNOL.getRowTop()) ||
                      selectedModularNOL.canItemBeDropped(device, selectedModularNOL.getRowBottom())
                    : false
                );
              }}
              padding=".5rem"
              width="136px"
            >
              {Localizations['add'][lang]}
            </Button>
          )}
          {showUnderframe && (
            <div className="mb-1">
              <Checkbox
                boxSize="1rem"
                centered={false}
                id={`underframe-checkbox-${device.id}`}
                label={Localizations['addUnderframe'][lang]}
                labelFontSize=".85rem"
                labelColor="black"
                status={hasUnderframe}
                setStatus={() => {
                  setHasUnderframe(!hasUnderframe);
                }}
              />
            </div>
          )}
          {(menuView === 'flexiChef' || ((menuView === 'modular' || menuView === 'modularNOL') && device?.model?.flexiChef)) && <FlexiChefOptions />}
          {menuView === 'spaceCombi' && <SpaceCombiOptions />}
        </div>
        {selection === scene?.getSelected() && ['W1BLG', 'W3Ev8', '8VgLk', 'WB1Ok', '8v7pk', '8y7aW'].includes(device?.category?.id) && (
          <div className="Submenu-Attribute mt-2">
            <label className="Submenu-Label">
              <input
                className="Submenu-Input"
                min={0}
                max={2000}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  setYEnhancement(val);
                }}
                step={Dimensions.MM(Dimensions.GRID)}
                type="number"
                value={yEnhancement}
              />
              {Localizations['installationHeight'][lang]} (mm)
            </label>
          </div>
        )}
        {(showTrashcan || selection === scene?.getSelected()) && (
          <div className="DeviceDetails-Small-Bottom">
            {
              // @ts-ignore
              (device.category.id === 'BW1Lk' || canHaveUpperstructures(selection)) && !isExternal && (
                <Icon
                  color="medium"
                  onClick={() => {
                    if (menuView === 'modular') {
                      setModularView('upperStructures');
                    } else if (menuView === 'modularNOL') {
                      setModularNOLView('upperStructures');
                    } else if (menuView === 'marineMeister') {
                      setMarineMeisterView('upperStructures');
                    } else {
                      setMasterlineView('upperStructures');
                    }
                    setSelection(scene.getSelected());
                  }}
                  size="1.5rem"
                  type="upperStructure"
                  stroke={3}
                  style={{ marginRight: '1rem' }}
                />
              )
            }
            {!(menuView === 'masterline' && selectedMasterline?.getUpperstrcutureOnly()) &&
              menuView !== 'spaceCombi' &&
              menuView !== 'flexiChef' &&
              menuView !== 'marineMeister' &&
              (((selection instanceof BlockItem || selection instanceof BlockGroup) && canHaveSubstructures(selection)) || device.model.flexiChef) && (
                <Icon
                  color="medium"
                  onClick={() => {
                    if (menuView === 'modular') {
                      setModularView('substructures');
                    } else if (menuView === 'modularNOL') {
                      setModularNOLView('substructures');
                    } else {
                      setMasterlineView('substructures');
                    }
                    setSelection(scene.getSelected());
                  }}
                  size="1.5rem"
                  type="substructures"
                  stroke={3}
                  style={{ marginRight: '1rem' }}
                />
              )}

            {(menuView === 'spaceCombi' || menuView === 'flexiChef') && (
              <div className="w-100 flex justify-end mx-2">
                {menuView === 'flexiChef' && (
                  <Icon
                    color="medium"
                    onClick={() => {
                      if (menuView === 'flexiChef') {
                        setFlexiMenuView('substructures');
                      }
                      setSelection(scene.getSelected());
                    }}
                    size="1.5rem"
                    type="substructures"
                    stroke={3}
                  />
                )}
                <Icon
                  type="rotate"
                  size="1.5rem"
                  onClick={() => {
                    if (selection instanceof FreeItem) {
                      selection.setRotation(new Vector3(0, selection.getRotation().y + Math.PI / 2, 0));
                    }
                  }}
                />
              </div>
            )}

            <Icon
              color="medium"
              onClick={() => {
                setErrorAlert(['delete']);
              }}
              size="1.5rem"
              type="trash"
              stroke={3}
            />
          </div>
        )}
      </div>
    </div>
  );
};
