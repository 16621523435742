import React, { FC, useContext } from 'react';

// Components
import { ContextMenu, DeviceDetailsSmall } from 'page/Editor/Menu/Shared';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import BlockItem from 'page/Editor/configuration/BlockItem';

// Context
import { EditorContext } from 'context';

// Types
import { Device } from 'types';

// Style
import { colors } from 'styles/theme';
import { VariantSelection } from 'page/Editor/Menu/Shared/DeviceDetailsSmall/Partials';

interface Props {
  isOverlay?: boolean;
  selectedDevice: Device[];
  showUnderframe?: boolean;
}

export const SelectedDevice: FC<Props> = ({ isOverlay = false, selectedDevice, showUnderframe = false }) => {
  const { scene, setSelection, menuView, variantKey } = useContext(EditorContext);

  const usedStyle = isOverlay ? { height: '100%', background: isOverlay ? colors.extraLight : 'transparent' } : { height: '100%' };

  return (
    <div style={usedStyle}>
      <div>
        <ContextMenu
          mode="back"
          onClick={() => {
            setSelection(null);
            scene.setSelected(null);
          }}
        />
      </div>
      <div className="Custom-Scroll h-full">
        {selectedDevice.map((device, index) => (
          <DeviceDetailsSmall
            key={`${device.id}-${index}`}
            device={device}
            showUnderframe={showUnderframe}
            showTrashcan={
              scene.getSelected() &&
              (scene.getSelected() instanceof BlockItem || scene.getSelected() instanceof BlockGroup) &&
              index === selectedDevice.length - 1
            }
          />
        ))}
        {(menuView === 'modularNOL' || menuView === 'masterline') && <VariantSelection device={selectedDevice[0]} />}
      </div>
    </div>
  );
};
