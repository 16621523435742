import React, { FC, useContext, useRef, useState, CSSProperties, useEffect } from 'react';

// Assets
import ArrowLeft from 'assets/svg/Icon_Arrow.svg';

// Atoms
import { ASearchbar, Icon } from 'atoms';

// Context
import { EditorContext, LangContext } from 'context';

// Languages
import Localizations from 'languages';

// Styles
import { colors } from 'styles/theme';
import '../style.scss';

// Utils
import { post } from 'utils';

interface Props {
  initActive?: boolean;
  border?: boolean;
  noMargin?: boolean;
  mode?: 'search' | 'back';
  onClick?: () => void;
  style?: CSSProperties;
}

export const ContextMenu: FC<Props> = ({ initActive = false, border = false, children, mode = 'search', noMargin = false, onClick, style = {} }) => {
  const [active, setActive] = useState(initActive);
  const { setSearchResults, setSearching, searchQuery, menuView, setSearchQuery, fullscreen } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const inputRef = useRef(null);

  const searchDevices = async () => {
    setSearching(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/search`, {
      data: {
        basicOnly: true,
        deviceType: 'Device',
        offset: 0,
        query: searchQuery,
        rows: 100,
        sort: {
          field: 'position',
          order: 'ASC'
        },
        type: menuView
      }
    });
    if (data) {
      setSearchResults(data);
      setSearching(false);
    }
    if (error) {
      console.log(error);
      setSearching(false);
    }
  };

  useEffect(() => {
    return () => {
      setSearching(false);
    };
  }, []);

  if (mode === 'search') {
    return (
      <div style={{ /* marginBottom: '54px' */ overflow: 'hidden', ...style }}>
        <ASearchbar key={`searchbar`} pose={active ? 'active' : 'inactive'}>
          <div className="flex">
            <div
              className="flex justify-between mb-4"
              style={{
                background: colors['extraLight'],
                marginRight: '50px',
                width: '280px',
                zIndex: active ? 9000 : 0
              }}
            >
              <Icon color={'medium'} onClick={searchDevices} size="1.25rem" stroke={3} type={'search'} />
              <div className="flex" style={{ flex: 1, borderBottom: `1px solid ${colors.lightBlue}`, marginLeft: '.5rem', paddingBottom: '.25rem' }}>
                <form
                  action=""
                  onSubmit={e => {
                    e.preventDefault();
                    searchDevices();
                  }}
                  style={{ flex: 1 }}
                >
                  <input
                    onChange={event => setSearchQuery(event.target.value)}
                    placeholder={`${Localizations['searchDevices'][lang]} ...`}
                    style={{
                      width: '95%',
                      background: 'transparent',
                      margin: '0 .5rem 0 0',
                      padding: '.25rem .25rem 0 0',
                      border: 'none',
                      outline: 'none'
                    }}
                    ref={inputRef}
                    type="text"
                    value={searchQuery}
                  />
                </form>
                <Icon
                  color="medium"
                  onClick={() => {
                    setActive(false);
                    setSearchQuery('');
                    setSearchResults(null);
                  }}
                  stroke={null}
                  size=".75rem"
                  type="close"
                />
              </div>
            </div>
            <div className={`Context-Menu${border ? (noMargin ? '-Border-NoMargin' : '-Border') : noMargin ? '-NoMargin' : ''}`}>
              {!(fullscreen && active) && (
                <Icon
                  color={'medium'}
                  onClick={() => {
                    setActive(true);
                    if (inputRef && inputRef.current) {
                      inputRef.current.focus();
                    }
                    if (onClick) {
                      onClick();
                    }
                  }}
                  size="1.25rem"
                  stroke={3}
                  type={'search'}
                />
              )}
              {children}
            </div>
          </div>
        </ASearchbar>
      </div>
    );
  }
  return (
    <div className={`Context-Menu${border ? (noMargin ? '-Border-NoMargin' : '-Border') : noMargin ? '-NoMargin' : ''}`} style={{ ...style }}>
      <img
        alt=""
        onClick={onClick}
        src={ArrowLeft}
        style={{ cursor: 'pointer', padding: '5px 0', height: '24px', width: '24px', transform: 'rotate(180deg)' }}
      />
      {children}
    </div>
  );
};
