import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// Styles:
import './styles/index.scss';

// Context:
import { AuthContext, EditorContext, LangContext, UserOrganization } from 'context';

// Components:
import { LoadingSpinner } from 'components';
import Block from 'page/Editor/configuration/Block';
import Scene, { Selected } from 'page/Editor/Scene';
import Configuration from './page/Editor/configuration/Configuration';

// Layout:
import { DefaultLayout } from 'layout/Default';

// Pages:
import { AGB, Imprint, Organization } from 'page';
import Archive from 'page/Archive';
import Editor from 'page/Editor';
import Home from 'page/Home';
import Login from 'page/Login';
import View from 'page/View';

// Types:
import {
  Device,
  DeviceImage,
  DeviceImageStorage,
  DeviceSeries,
  Dialog,
  ErrorAlert,
  FlexiMenuView,
  FullscreenView,
  Language,
  LanguageAbbreviation,
  MasterlineMenu,
  MenuView,
  ModularNOLView,
  ModularView,
  RoomView,
  SavedConfiguration
} from 'types';

// Utils:
import { Right } from 'types/Member';
import { get, getLanguages, initializeContainsMerges } from 'utils';
import { MarineMeisterMenu } from './types/MarineMeisterMenu';

// ==============================================================
const App: FC = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [canAdvanceMarineMeister, setCanAdvanceMarineMeister] = useState(false);
  const [canAdvanceMasterline, setCanAdvanceMasterline] = useState(false);
  const [canAdvanceModular, setCanAdvanceModular] = useState(false);
  const [canAdvanceModularNOL, setCanAdvanceModularNOL] = useState(false);
  const [configuration, setConfiguration] = useState<Configuration>(null);
  const [containsMerges, setContainsMerges] = useState(false);
  const [coverEnlargementSize, setCoverEnlargementSize] = useState('');
  const [deviceImages, setDeviceImages] = useState<DeviceImageStorage>(null);
  const [dialog, setDialog] = useState<Dialog>(null);
  const [discountFlexiChef, setDiscountFlexiChef] = useState(0);
  const [discountMasterline, setDiscountMasterline] = useState(0);
  const [discountModular, setDiscountModular] = useState(0);
  const [discountSpaceCombi, setDiscountSpaceCombi] = useState(0);
  const [errorAlert, setErrorAlert] = useState<ErrorAlert[]>(null);
  const [flexiMenuView, setFlexiMenuView] = useState<FlexiMenuView>('main');
  const [fullscreen, setFullscreen] = useState<FullscreenView>(null);
  const [hasMarineMeister, setHasMarineMeister] = useState(false);
  const [hasMasterline, setHasMasterline] = useState(false);
  const [lang, setLang] = useState<LanguageAbbreviation>(() => {
    const lang = window.localStorage.getItem('lang');
    return lang === 'de' || lang === 'en' || lang === 'cz' || lang === 'fr' ? lang : 'de';
  });
  const [langId, setLangId] = useState('vdrqR');
  const [languages, setLanguages] = useState<Language[]>([]);
  const [loading, setLoading] = useState(true);
  const [marineMeisterView, setMarineMeisterView] = useState<MarineMeisterMenu>('home');
  const [masterlineView, setMasterlineView] = useState<MasterlineMenu>('home');
  const [menuView, setMenuView] = useState<MenuView>('room');
  const [modularView, setModularView] = useState<ModularView>('home');
  const [modularNOLView, setModularNOLView] = useState<ModularNOLView>('home');
  const [organization, setOrganization] = useState<UserOrganization | null>(null);
  const [owner, setOwner] = useState(true);
  const [profileOpen, setProfileOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [rights, setRights] = useState<Right[]>([]);
  const [roomView, setRoomView] = useState<RoomView>('home');
  const [savedConfiguration, setSavedConfiguration] = useState<SavedConfiguration | null>(null);
  const [savingError, setSavingError] = useState(false);
  const [scene, setScene] = useState<Scene | null>(null);
  const [selectedMarineMeister, setSelectedMarineMeister] = useState<Block>(null);
  const [selectedMasterline, setSelectedMasterline] = useState<Block>(null);
  const [selectedModular, setSelectedModular] = useState<Block>(null);
  const [selectedModularNOL, setSelectedModularNOL] = useState<Block>(null);
  const [selection, setSelection] = useState<Selected | Device | null>(null);
  const [seriesFilter, setSeriesFilter] = useState<DeviceSeries>('all');
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<Device[]>(null);
  const [supervisor, setSupervisor] = useState(false);
  const [showLoadingError, setShowLoadingError] = useState(false);
  const [surchargeMasterline, setSurchargeMasterline] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  const [variantKey, setVariantKey] = useState('');

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    setUserEmail(window.localStorage.getItem('user_email_configurator'));
    window.addEventListener('resize', appHeight);
    appHeight();
  }, []);

  useEffect(() => {
    if (selectedModular) {
      const depths = [];
      const rowTop = selectedModular.getRowTop();
      const rowBottom = selectedModular.getRowBottom();
      if (rowTop) {
        depths.push(rowTop.getDepth());
      }
      if (rowBottom) {
        depths.push(rowBottom.getDepth());
      }
      if (depths.includes(700) && !depths.includes(850)) {
        setSeriesFilter('700');
      }
      if (depths.includes(850) && !depths.includes(700)) {
        setSeriesFilter('850');
      }
      if (depths.includes(850) && depths.includes(700)) {
        setSeriesFilter('all');
      }
    }
  }, [selectedModular]);

  if (!window['statusInterval']) {
    // Check status every 15 min
    window['statusInterval'] = setInterval(async () => fetchStatus(), 900000);
  }

  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  // Methods
  const fetchStatus = async () => {
    const { data, error } = await get(`${process.env.REACT_APP_API_URL}/auth/status`, {});
    if (error) setAuthenticated(false);
    if (data) {
      if (data.key) window.localStorage.setItem('jwt', data.key);
      setAuthenticated(data.loggedIn);
      setOrganization(data.organization);
      setSupervisor(data.supervisor);
      setRights(data.rights);
    }
    setLoading(false);
  };

  const fetchLanguages = async () => {
    const { data, error } = await getLanguages();
    if (data) {
      setLanguages(data);
    }
    if (error) {
      console.log(error);
    }
  };

  const handleAddImage = (newImage: DeviceImage) => {
    setDeviceImages(prevDevices => ({ ...prevDevices, [newImage.id]: newImage.blob }));
  };

  useEffect(() => {
    if (languages && languages.length > 0) {
      const { id } = languages.find((d: Language) => d.code === lang);
      if (id) {
        setLangId(id);
      }
    }
  }, [languages, lang]);

  // Effect
  useEffect(() => {
    fetchStatus();
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchLanguages();
      fetchStatus();
    }
  }, [authenticated]);

  useEffect(() => {
    const selectedBlock = menuView === 'masterline' ? selectedMasterline : menuView === 'modular' ? selectedModular : null;

    const initializeSeriesFilter = () => {
      const depths = [];

      if (selectedBlock && selectedBlock.getRowTop()) {
        depths.push(selectedBlock.getRowTop().getDepth());
      }
      if (selectedBlock && selectedBlock.getRowBottom()) {
        depths.push(selectedBlock.getRowBottom().getDepth());
      }
      if (depths.includes(700) && !depths.includes(850)) {
        setSeriesFilter('700');
      }
      if (depths.includes(850) && !depths.includes(700)) {
        setSeriesFilter('850');
      }
      if (depths.includes(850) && depths.includes(700)) {
        setSeriesFilter('all');
      }
    };
    initializeSeriesFilter();

    if (selectedBlock) {
      setContainsMerges(initializeContainsMerges(selectedBlock));
    } else {
      setContainsMerges(false);
    }
  }, [configuration, menuView, selectedMasterline, selectedModular]);

  useEffect(() => {
    if (configuration) {
      const masterlines = configuration.getMasterline();
      setHasMasterline(!!(Array.isArray(masterlines) && masterlines.length > 0));
    } else {
      setHasMasterline(false);
      setSurchargeMasterline(0);
    }
  }, [configuration]);

  useEffect(() => {
    setMasterlineView('home');
    setModularView('home');
    setModularNOLView('home');
  }, [menuView]);

  useEffect(() => {
    setSearchResults(null);
    setErrorAlert(null);
  }, [menuView, selection]);

  useEffect(() => {
    if (configuration) {
      configuration.setDiscountMasterline(discountMasterline);
    }
  }, [discountMasterline]);

  useEffect(() => {
    if (configuration) {
      configuration.setDiscountModular(discountModular);
    }
  }, [discountModular]);

  useEffect(() => {
    if (configuration) {
      configuration.setDiscountSpaceCombi(discountSpaceCombi);
    }
  }, [discountSpaceCombi]);

  useEffect(() => {
    if (configuration) {
      configuration.setDiscountFlexiChef(discountFlexiChef);
    }
  }, [discountFlexiChef]);

  useEffect(() => {
    if (configuration) {
      configuration.setSurchargeMasterline(surchargeMasterline);
    }
  }, [surchargeMasterline]);

  useEffect(() => {
    if (organization && configuration) {
      setDiscountFlexiChef(organization.discountFlexiChef || 0);
      setDiscountMasterline(organization.discountMasterline || 0);
      setDiscountModular(organization.discountModular || 0);
      setDiscountSpaceCombi(organization.discountSpaceCombi || 0);
    }
  }, [configuration, organization]);

  // Render
  return loading ? (
    <div
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <LoadingSpinner size="100px" />
    </div>
  ) : (
    <AuthContext.Provider
      value={{
        status: authenticated,
        handleLog: (status: boolean) => {
          if (!status) {
            window.localStorage.removeItem('jwt');
            window.localStorage.removeItem('user_email_configurator');
            setOrganization(null);
            setSupervisor(null);
            setRights([]);
            setUserEmail(null);
          }

          setAuthenticated(status);
        },
        organization,
        setOrganization,
        supervisor,
        setSupervisor,
        userRights: rights,
        setUserRights: (r: Right[]) => setRights(r),
        userEmail,
        setUserEmail
      }}
    >
      <LangContext.Provider
        value={{
          lang: lang,
          setLang: (lang: LanguageAbbreviation) => {
            window.localStorage.setItem('lang', lang);
            setLang(lang);
          },
          langId: langId
        }}
      >
        <EditorContext.Provider
          value={{
            canAdvanceMarineMeister,
            setCanAdvanceMarineMeister,
            canAdvanceMasterline,
            setCanAdvanceMasterline,

            coverEnlargementSize,
            setCoverEnlargementSize,

            canAdvanceModular,
            setCanAdvanceModular,

            canAdvanceModularNOL,
            setCanAdvanceModularNOL,

            configuration,
            setConfiguration,

            containsMerges,
            setContainsMerges,

            deviceImageStorage: deviceImages,
            addImageToStorage: (newImage: DeviceImage) => {
              handleAddImage(newImage);
            },

            dialog,
            setDialog,

            discountFlexiChef,
            setDiscountFlexiChef,

            discountMasterline,
            setDiscountMasterline,

            discountModular,
            setDiscountModular,

            discountSpaceCombi,
            setDiscountSpaceCombi,

            errorAlert: errorAlert,
            setErrorAlert,

            flexiMenuView,
            setFlexiMenuView,

            fullscreen: fullscreen,
            setFullscreen,

            hasMarineMeister,
            setHasMarineMeister,

            hasMasterline,
            setHasMasterline,

            marineMeisterView,
            setMarineMeisterView,

            masterlineView,
            setMasterlineView,

            menuView: menuView,
            setMenuView,

            modularView,
            setModularView,

            modularNOLView,
            setModularNOLView,

            owner,
            setOwner,

            profileOpen,
            setProfileOpen,

            roomView,
            setRoomView,

            savedConfiguration,
            setSavedConfiguration,

            savingError,
            setSavingError,

            searching,
            setSearching,

            searchQuery: query,
            setSearchQuery: (q: string) => setQuery(q),

            selectedMarineMeister,
            setSelectedMarineMeister,

            selectedMasterline,
            setSelectedMasterline,

            selectedModular,
            setSelectedModular,

            selectedModularNOL,
            setSelectedModularNOL,

            selection: selection,
            setSelection,

            seriesFilter: seriesFilter,
            setSeriesFilter,

            scene: scene,
            setScene,

            searchResults: searchResults,
            setSearchResults,

            showLoadingError,
            setShowLoadingError,

            surchargeMasterline,
            setSurchargeMasterline,
            variantKey,
            setVariantKey
          }}
        >
          {authenticated ? (
            <DefaultLayout>
              <Switch>
                <Route exact path="/" component={Home} />
                {rights?.includes('Admin') && <Route path="/archive" component={Archive} />}
                <Route path="/editor" component={Editor} />
                <Route path="/view" component={View} />
                <Route path="/imprint" component={Imprint} />
                <Route path="/agb" component={AGB} />
                {!!organization && <Route path="/organization" component={Organization} />}
                <Redirect to="/" />
              </Switch>
            </DefaultLayout>
          ) : (
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/password-reset" component={Login} />
              <Route path="/view" component={View} />
              <Redirect to="/" />
            </Switch>
          )}
        </EditorContext.Provider>
      </LangContext.Provider>
    </AuthContext.Provider>
  );
};
const AppWithRouter = withRouter(App);

// ==============================================================
ReactDOM.render(
  <Router>
    <AppWithRouter />
  </Router>,
  document.getElementById('root')
);
serviceWorker.unregister();
