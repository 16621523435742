import { EditorContext, LangContext } from 'context';
import { useContext, useEffect, useState } from 'react';
import { Device } from 'types';
import { post } from 'utils';
import Localizations from 'languages';

export const useInstallationWall = (initialized: boolean) => {
  const { selectedModularNOL } = useContext(EditorContext);
  const { lang, langId } = useContext(LangContext);

  const [selectedInstallationWall, setSelectedInstallationWall] = useState<string>('');
  const [selectedDepth, setSelectedDepth] = useState(100);
  const [selectedHeight, setSelectedHeight] = useState(950);
  const [installationWallDevices, setInstallationWallDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState(false);
  const [canEditHeight, setCanEditHeight] = useState(false);
  const WALL_WITH_HEIGHT_ID = 'lwNZp';

  useEffect(() => {
    const installationWallkey = `nstlltnwll`;
    const getInstallationWalls = async () => {
      setLoading(true);
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'modular',
          device: {
            subtype: 'InstallationWall'
          }
        }
      });
      if (Array.isArray(data)) {
        setInstallationWallDevices(data);
        sessionStorage.setItem(installationWallkey, JSON.stringify(data));
        setLoading(false);
      }
      if (error) {
        console.log(error);
        setLoading(false);
      }
    };
    const cachedWalls = sessionStorage.getItem(installationWallkey);
    if (cachedWalls) {
      setInstallationWallDevices(JSON.parse(cachedWalls));
    } else {
      getInstallationWalls();
    }
  }, []);

  useEffect(() => {
    // Initialize
    if (!initialized && selectedModularNOL) {
      const iWall = selectedModularNOL.getInstallationWallDevice();
      const iWallDepth = selectedModularNOL.getInstallationWallDepth();
      const iWallHeight = selectedModularNOL.getInstallationWallHeight();
      setSelectedInstallationWall(iWall ? iWall.translations[langId]?.name : Localizations['none'][lang]);
      if (selectedModularNOL.getInstallationWallHeight() > 0) {
        setSelectedHeight(selectedModularNOL.getInstallationWallHeight());
      } else {
        setSelectedHeight(950);
      }
      setSelectedDepth(iWallDepth);
      setCanEditHeight(iWall?.id === WALL_WITH_HEIGHT_ID);
    }
  }, [initialized, selectedModularNOL, langId]);

  const handleSelectInstallationWall = (newWall: string) => {
    const device = installationWallDevices.find(d => d.translations[langId]?.name === newWall);
    selectedModularNOL.setInstallationWall(device ? device : null);
    setSelectedInstallationWall(newWall);
    setSelectedDepth(selectedModularNOL.getInstallationWallDepth());
    setCanEditHeight(device?.id === WALL_WITH_HEIGHT_ID);
    if (selectedModularNOL.getInstallationWallHeight() > 0) {
      setSelectedHeight(selectedModularNOL.getInstallationWallHeight());
    } else {
      setSelectedHeight(950);
    }
  };

  const handleSelectInstallationDepth = (newDepth: number) => {
    selectedModularNOL?.setInstallationWallDepth(newDepth);
    setSelectedDepth(selectedModularNOL.getInstallationWallDepth());
  };

  const handleSelectInstallationHeight = (newHeight: number) => {
    selectedModularNOL?.setInstallationWallHeight(newHeight);
    setSelectedHeight(selectedModularNOL.getInstallationWallHeight());
  };

  return {
    selectedInstallationWall,
    setSelectedInstallationWall: handleSelectInstallationWall,
    selectedInstallationWallDepth: selectedDepth,
    setSelectedInstallationWallDepth: handleSelectInstallationDepth,
    installationWallDevices,
    installationWallLoading: loading,
    canEditHeight,
    selectedInstallationWallHeight: selectedHeight,
    setSelectedInstallationWallHeight: handleSelectInstallationHeight
  };
};
