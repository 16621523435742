import * as BABYLON from 'babylonjs';
import Block from 'page/Editor/configuration/Block';
import Scene from 'page/Editor/Scene';
import HighPerformanceQueue from '../helper/HighPerformanceQueue';
import BasicUtils from '../util/BasicUtils';
import LabelUtils, { Orientation } from '../util/LabelUtils';
import LineUtils from '../util/LineUtils';
import LoaderUtils from '../util/LoaderUtils';
import MarkUtils from '../util/MarkUtils';
import MaterialUtils from '../util/MaterialUtils';
import BlockBoardNode from './BlockBoardNode';
import BlockRowNode from './BlockRowNode';
import DeviceNode from './DeviceNode';

type BlockNodeDefaultSettings = {
  materials: {
    ground?: BABYLON.StandardMaterial;
    grab?: BABYLON.StandardMaterial;
  };
  grab: {
    lineHeight: number;
    size: number;
    offset: number;
  };
};

type GrabNodes = {
  node?: BABYLON.TransformNode;
  left?: BABYLON.TransformNode;
  right?: BABYLON.TransformNode;
  line: {
    leftH?: BABYLON.TransformNode;
    leftV?: BABYLON.TransformNode;
    rightH?: BABYLON.TransformNode;
    rightV?: BABYLON.TransformNode;
    label?: BABYLON.AbstractMesh;
  };
};

type BaseNodes = {
  node?: BABYLON.TransformNode;
  top?: BABYLON.Mesh;
  right?: BABYLON.Mesh;
  bottom?: BABYLON.Mesh;
  left?: BABYLON.Mesh;
  cover?: BABYLON.Mesh;
  materialX?: BABYLON.PBRMaterial;
  materialZ?: BABYLON.PBRMaterial;
  materialCover?: BABYLON.PBRMaterial;
};

export default class BlockNode extends BABYLON.TransformNode {
  public static defaultSettings: BlockNodeDefaultSettings = {
    materials: {},
    grab: {
      lineHeight: 20,
      size: 40,
      offset: 40
    }
  };

  public static initialize() {
    // Ground Materials
    this.defaultSettings.materials.ground = new BABYLON.StandardMaterial('block:ground', Scene.CURRENT_SCENE);
    this.defaultSettings.materials.ground.diffuseColor = BABYLON.Color3.Black();
    this.defaultSettings.materials.ground.specularColor = BABYLON.Color3.Black();
    this.defaultSettings.materials.ground.emissiveColor = new BABYLON.Color3(0.8, 0.8, 0.8);
    this.defaultSettings.materials.ground.alpha = 0.4;
    this.defaultSettings.materials.ground.disableLighting = true;
    this.defaultSettings.materials.ground.freeze();
    // Grab Material
    this.defaultSettings.materials.grab = new BABYLON.StandardMaterial('block:grab', Scene.CURRENT_SCENE);
    this.defaultSettings.materials.grab.diffuseColor = BABYLON.Color3.Black();
    this.defaultSettings.materials.grab.specularColor = BABYLON.Color3.Black();
    this.defaultSettings.materials.grab.emissiveColor = new BABYLON.Color3(1, 1, 1);
    const grabTexture = new BABYLON.Texture('texture/size_grab.png', Scene.CURRENT_SCENE);
    grabTexture.hasAlpha = true;
    this.defaultSettings.materials.grab.diffuseTexture = grabTexture;
    this.defaultSettings.materials.grab.opacityTexture = grabTexture;
    this.defaultSettings.materials.grab.disableLighting = true;
    this.defaultSettings.materials.grab.freeze();
  }

  private _block: Block;

  private _rows: BABYLON.TransformNode;
  private _top: BlockRowNode;
  private _bottom: BlockRowNode;
  private _base: BaseNodes = {};

  private _grab: GrabNodes = {
    line: {}
  };

  private _showGrab: boolean;

  private _dropMark: BABYLON.Mesh;

  private _blockBoardNode: BlockBoardNode;

  private _labelX: BABYLON.TransformNode;
  private _labelY: BABYLON.TransformNode;
  private _labelZ: BABYLON.TransformNode;

  private _installationWall: BABYLON.TransformNode;
  private _extension: BABYLON.TransformNode;
  private _output: BABYLON.TransformNode;
  private _outputMark: BABYLON.TransformNode;

  private _cutLabelTop: BABYLON.TransformNode;
  private _cutLabelBottom: BABYLON.TransformNode;
  private _cutLabelLeft: BABYLON.TransformNode;
  private _cutLabelRight: BABYLON.TransformNode;

  private _sideBoardLeft: BABYLON.TransformNode;
  private _sideBoardLeftLoaded: boolean;
  private _sideBoardRight: BABYLON.TransformNode;
  private _sideBoardRightLoaded: boolean;

  constructor(name: string, block: Block) {
    super('block.' + name, Scene.CURRENT_SCENE, undefined);
    this.id = 'block';
    this._block = block;
    this.setEnabled(false);

    this._outputMark = new BABYLON.TransformNode('output', this.getScene());
    this._outputMark.parent = this;
    this._outputMark.setEnabled(false);

    this._rows = new BABYLON.TransformNode('rows', Scene.CURRENT_SCENE);
    this._rows.parent = this;
    this._top = new BlockRowNode('top', this._rows);
    this._bottom = new BlockRowNode('bottom', this._rows);

    //#region Grab
    // Add Grab
    this._grab.node = new BABYLON.TransformNode('grabs', Scene.CURRENT_SCENE);
    this._grab.node.parent = this;
    // Left grab
    this._grab.left = this.createGrabSpot('left', this._grab.node);
    // Right grab
    this._grab.right = this.createGrabSpot('right', this._grab.node);
    // Line
    this._grab.line.leftV = LineUtils.drawLine(
      'grab.line.left.vertical',
      {
        path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, BlockNode.defaultSettings.grab.lineHeight)],
        material: LabelUtils.lineMaterialBlue,
        width: 1
      },
      Scene.CURRENT_SCENE
    );
    this._grab.line.leftV.parent = this._grab.node;
    this._grab.line.leftH = LineUtils.drawLine(
      'grab.line.left.horizontal',
      {
        path: [
          new BABYLON.Vector3(0, 0, BlockNode.defaultSettings.grab.lineHeight / 2 - 0.5),
          new BABYLON.Vector3(1, 0, BlockNode.defaultSettings.grab.lineHeight / 2 - 0.5)
        ],
        material: LabelUtils.lineMaterialBlue,
        width: 1
      },
      Scene.CURRENT_SCENE
    );
    this._grab.line.leftH.parent = this._grab.node;
    this._grab.line.rightV = LineUtils.drawLine(
      'grab.line.right.vertical',
      {
        path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, BlockNode.defaultSettings.grab.lineHeight)],
        material: LabelUtils.lineMaterialBlue,
        width: 1
      },
      Scene.CURRENT_SCENE
    );
    this._grab.line.rightV.parent = this._grab.node;
    this._grab.line.rightH = LineUtils.drawLine(
      'grab.line.right.horizontal',
      {
        path: [
          new BABYLON.Vector3(0, 0, BlockNode.defaultSettings.grab.lineHeight / 2 - 0.5),
          new BABYLON.Vector3(1, 0, BlockNode.defaultSettings.grab.lineHeight / 2 - 0.5)
        ],
        material: LabelUtils.lineMaterialBlue,
        width: 1
      },
      Scene.CURRENT_SCENE
    );
    this._grab.line.rightH.parent = this._grab.node;
    // Label
    this._grab.line.label = BABYLON.Mesh.CreateGround('text', LabelUtils.labelWidth, LabelUtils.labelWidth, 1);
    this._grab.line.label.parent = this._grab.node;
    const labelMaterial = new BABYLON.StandardMaterial('grab:label', Scene.CURRENT_SCENE);
    labelMaterial.diffuseColor = BABYLON.Color3.Black();
    labelMaterial.specularColor = BABYLON.Color3.Black();
    labelMaterial.emissiveColor = LabelUtils.lineMaterialBlue.emissiveColor;
    labelMaterial.disableLighting = true;
    this._grab.line.label.material = labelMaterial;
    this._grab.line.label.position.z = LabelUtils.labelWidth / 4;

    this._grab.node.position.y = 0.2;
    this._grab.node.setEnabled(false);
    //#endregion

    //#region Drop mark
    const dropMark = BABYLON.Mesh.CreateBox('dropMark', 1, Scene.CURRENT_SCENE);
    dropMark.scaling.x = 10;
    dropMark.scaling.y = 180;
    dropMark.position.y = dropMark.scaling.y / 2;
    dropMark.material = Scene.CURRENT_SCENE.getMaterialByName('drop_green');
    dropMark.setEnabled(false);
    this._dropMark = dropMark;
    //#endregion

    this.setEnabled(true);

    this._base.node = new BABYLON.TransformNode('base', this.getScene());
    this._base.node.parent = this;
    this._base.node.setEnabled(false);
    const baseMaterialX = new BABYLON.PBRMaterial('base:x:' + this.uniqueId, this.getScene());
    baseMaterialX.albedoColor = BasicUtils.color(100, 100, 100);
    baseMaterialX.albedoTexture = MaterialUtils.TEXTURE_TILE.clone();
    baseMaterialX.bumpTexture = MaterialUtils.TEXTURE_TILE_NORMAL.clone();
    baseMaterialX.metallic = 0.2;
    baseMaterialX.roughness = 0.05;
    baseMaterialX.clearCoat.isEnabled = true;
    baseMaterialX.clearCoat.intensity = 0.2;
    baseMaterialX.clearCoat.roughness = 0.1;
    baseMaterialX.clearCoat.indexOfRefraction = 3;
    baseMaterialX.specularIntensity = 0.15;
    baseMaterialX.directIntensity = 1;
    baseMaterialX.enableSpecularAntiAliasing = true;
    this._base.materialX = baseMaterialX;
    const baseMaterialZ = baseMaterialX.clone('base:z:' + this.uniqueId);
    this._base.materialZ = baseMaterialZ;
    const baseMaterialCover = baseMaterialX.clone('base:cover:' + this.uniqueId);
    this._base.materialCover = baseMaterialCover;
    this._base.top = BABYLON.Mesh.CreatePlane('top', 1, this.getScene());
    this._base.top.parent = this._base.node;
    this._base.top.rotation.y = Math.PI;
    this._base.top.material = baseMaterialX;
    this._base.top.receiveShadows = true;
    this._base.right = BABYLON.Mesh.CreatePlane('right', 1, this.getScene());
    this._base.right.parent = this._base.node;
    this._base.right.rotation.y = -Math.PI / 2;
    this._base.right.material = baseMaterialZ;
    this._base.right.receiveShadows = true;
    this._base.bottom = BABYLON.Mesh.CreatePlane('bottom', 1, this.getScene());
    this._base.bottom.parent = this._base.node;
    this._base.bottom.material = baseMaterialX;
    this._base.bottom.receiveShadows = true;
    this._base.left = BABYLON.Mesh.CreatePlane('left', 1, this.getScene());
    this._base.left.parent = this._base.node;
    this._base.left.rotation.y = Math.PI / 2;
    this._base.left.material = baseMaterialZ;
    this._base.left.receiveShadows = true;
    this._base.cover = BABYLON.Mesh.CreateGround('cover', 1, 1, 1, this.getScene());
    this._base.cover.parent = this._base.node;
    this._base.cover.material = baseMaterialCover;
    this._base.cover.receiveShadows = true;

    this._output = new BABYLON.TransformNode('output', Scene.CURRENT_SCENE);
    this._output.parent = this;

    // Extension
    LoaderUtils.load('/gltf/generic_double_corpus_extension.gltf', lr => {
      const base = lr.model;
      base.name = 'extension.' + this.uniqueId;
      base.parent = DeviceNode.defaultSettings.modelNode;
      const cloneLeft = [
        'PedestrialCover',
        'BorderAngular',
        'BorderAngular_chamfer',
        'BorderAngular_upturn',
        'BorderAngular_weld',
        'BorderRound',
        'BorderRoundRound',
        'BorderTrench',
        'BorderTrench_NOL',
        'BorderTrench_NOL_sink',
        'Corpus',
        'CorpusSideCover',
        'HandleRound_side'
      ];
      for (let i = 0; i < cloneLeft.length; i++) {
        const name = cloneLeft[i];
        const node = BasicUtils.findFirstChild(name, base);
        if (node) {
          const left = BasicUtils.findFirstChild('Left', node);
          const right = BasicUtils.clone(left, 'Right', node);
          right.scaling.x = -1;

          if (name === 'CorpusSideCover') {
            node.scaling.z = 1.1;
          }
        }
      }

      base.getChildMeshes().forEach(mesh => {
        if (mesh.material) {
          if (mesh.material.name === '_metal_side') mesh.material = this.getBlock().getDoorColorMaterial();
        }
      });

      base.setEnabled(false);
      this._extension = base;
      this.bake();
    });
  }

  public setPosition(position: { x?: number; y?: number; z?: number }) {
    // console.log('setPosition', position);
    if (typeof position.x !== 'undefined') {
      this.position.x = position.x;
      this._block.getPosition().x = position.x;
    }
    if (typeof position.y !== 'undefined') {
      this.position.y = position.y;
      this._block.getPosition().y = position.y;
    }
    if (typeof position.z !== 'undefined') {
      this.position.z = position.z;
      this._block.getPosition().z = position.z;
    }
  }

  private createGrabSpot(name: 'left' | 'right', parent: BABYLON.TransformNode) {
    const grab = BABYLON.Mesh.CreateGround('grab.' + name, BlockNode.defaultSettings.grab.size, BlockNode.defaultSettings.grab.size, 1, Scene.CURRENT_SCENE);
    grab.material = BlockNode.defaultSettings.materials.grab;
    grab.parent = parent;
    grab.position.z = BlockNode.defaultSettings.grab.offset;
    return grab;
  }

  public getWidth() {
    if (this.getBlock().isFlex()) {
      let wt = 0;
      let wb = 0;
      if (this._top.isEnabled()) {
        wt = this._top.getRealWidth();
      }
      if (this._bottom.isEnabled()) {
        wb = this._bottom.getRealWidth();
      }
      return Math.max(200, Math.max(wt, wb));
    } else {
      return this.getBlock().getWidth();
    }
  }

  public updateDimension() {
    const width = this.getWidth();
    this._rows.position.x = -width / 10 / 2;
    // Reset Bottom Row Position
    this._bottom.position.z = 0;

    // Move Bottom Row based on Config
    if (this.getBlock().isInstallationWall()) this._bottom.position.z -= this.getBlock().getInstallationWallDepth() / 10;

    // Enable / Disable Top Row
    if (this.getBlock().getRowTop()) {
      this._top.setEnabled(true);
      this._top.updateGround(width);
    } else {
      this._top.setEnabled(false);
    }
    this._bottom.updateGround(width);
    this._bottom.position.z -= (this.getBlock().getDepthExtension() + this.getBlock().getRowBottom().getDepthExtended()) / 10;

    //if (this.getBlockBoardNode()) this.getBlockBoardNode().position.z = this.getBlock().getDepthExtension() / 20;

    this.updateBottomBase();

    this.updateGrabDiminsion(width);
    this.scaleInstallationWall();
    this.getBlock().checkDeviceStatus();
  }

  public updateBottomBase() {
    if (this._block.getBottom() === 'Base' && !!!this._block.getUpperstrcutureOnly()) {
      const width = this._block.getWidth();
      const offsetZ = 99;
      const offsetX = 19;

      const scalingX = (width - 2 * offsetX) / 10;
      const scalingZ =
        (this._block.getRowBottom().getDepthExtended() -
          offsetZ +
          (this._block.getRowTop() ? this._block.getRowTop().getDepthExtended() - offsetZ : 0) +
          this._block.getDepthExtension() +
          this._block.getInstallationWallDepth() +
          (this._block.getType() === 'Single' ? (this._block.getSingleType() === 'Free' ? -50 : -10) : 0)) /
        10;
      const scalingY = this._block.getBottomHeight();

      // Scale
      this._base.top.scaling.x = scalingX;
      this._base.bottom.scaling.x = scalingX;

      this._base.right.scaling.x = scalingZ;
      this._base.left.scaling.x = scalingZ;

      this._base.top.scaling.y = scalingY;
      this._base.bottom.scaling.y = scalingY;
      this._base.right.scaling.y = scalingY;
      this._base.left.scaling.y = scalingY;

      this._base.cover.scaling.x = scalingX;
      this._base.cover.scaling.z = scalingZ;

      // Position
      this._base.node.position.y = scalingY / 2;

      this._base.top.position.z = scalingZ / 2;
      this._base.bottom.position.z = -this._base.top.position.z;
      this._base.right.position.x = scalingX / 2;
      this._base.left.position.x = -this._base.right.position.x;

      this._base.cover.position.y = scalingY / 2;

      if (this._block.getType() === 'Double') {
        this._base.node.position.z = -(this._block.getRowBottom().getDepthExtended() - this._block.getRowTop().getDepthExtended()) / 20;
      } else {
        this._base.node.position.z = -this._block.getRowBottom().getDepthExtended() / 20;
        switch (this._block.getSingleType()) {
          case 'Free':
            this._base.node.position.z += 2.5;
            break;
          case 'Wall':
            this._base.node.position.z += 5;
            break;
        }
      }
      this._base.node.position.z -= this._block.getDepthExtension() / 20;
      if (this._block.isInstallationWall()) this._base.node.position.z -= this._block.getInstallationWallDepth() / 20;

      // Update Textures
      const textureScaleX = scalingX / 10 / 2;
      const textureScaleZ = scalingZ / 10 / 2;

      (this._base.materialX.albedoTexture as BABYLON.Texture).uScale = textureScaleX;
      (this._base.materialZ.albedoTexture as BABYLON.Texture).uScale = textureScaleZ;
      (this._base.materialCover.albedoTexture as BABYLON.Texture).uScale = textureScaleX;
      (this._base.materialCover.albedoTexture as BABYLON.Texture).vScale = textureScaleZ;

      (this._base.materialX.bumpTexture as BABYLON.Texture).uScale = textureScaleX;
      (this._base.materialZ.bumpTexture as BABYLON.Texture).uScale = textureScaleZ;
      (this._base.materialCover.bumpTexture as BABYLON.Texture).uScale = textureScaleX;
      (this._base.materialCover.bumpTexture as BABYLON.Texture).vScale = textureScaleZ;

      this._base.node.setEnabled(true);
    } else {
      this._base.node.setEnabled(false);
    }
    this.bake();
  }

  public isShowGrab() {
    return this._showGrab;
  }

  public setShowGrab(showGrab: boolean) {
    this._showGrab = showGrab;

    if (this._block.isFlex()) this._block.setFlex(false);

    this._grab.node.setEnabled(showGrab);

    this.updateDimension();
    //this.updateGrabDiminsion(this.getWidth());
  }

  private updateGrabDiminsion(width: number) {
    if (this._showGrab) {
      this._grab.node.position.z = 0;
      if (this._block.getRowTop()) {
        this._grab.node.position.z = this._block.getRowTop().getDepth() / 10;
      }

      const x = width / 10 / 2;
      this._grab.left.position.x = -x;
      this._grab.right.position.x = x;

      const halfLabelWidth = LabelUtils.labelWidth / 2;
      this._grab.line.leftV.position.x = -x;
      this._grab.line.leftH.scaling.x = Math.max(0, x - halfLabelWidth);
      this._grab.line.leftH.position.x = -x;
      this._grab.line.rightV.position.x = x;
      this._grab.line.rightH.scaling.x = this._grab.line.leftH.scaling.x;
      this._grab.line.rightH.position.x = halfLabelWidth;
      (this._grab.line.label.material as BABYLON.StandardMaterial).opacityTexture = LabelUtils.drawText('' + width);
    }
  }

  public checkGrab() {
    const x = this.getWidth() / 10 / 2;
    if (this._grab.left.position.x !== -x) {
      const diff = Math.abs(this._grab.left.position.x - -x) * 10;
      const add = this._grab.left.position.x < -x;
      this._grab.left.position.x = -x;
      if (add) {
        this._block.setWidth(this._block.getWidth() + diff, true);
      } else {
        this._block.setWidth(this._block.getWidth() - diff, true);
      }
    } else if (this._grab.right.position.x !== x) {
      const diff = Math.abs(this._grab.right.position.x - x) * 10;
      const add = this._grab.right.position.x > x;
      this._grab.right.position.x = x;
      if (add) {
        this._block.setWidth(this._block.getWidth() + diff, true);
      } else {
        this._block.setWidth(this._block.getWidth() - diff, true);
      }
    }
  }

  public setDropMark(row: 'Top' | 'Bottom', x: number) {
    switch (row) {
      case 'Top':
        this._dropMark.parent = this._top;
        this._dropMark.scaling.z = this._block.getRowTop().getDepth() / 10;
        break;
      case 'Bottom':
        this._dropMark.parent = this._bottom;
        this._dropMark.scaling.z = this._block.getRowBottom().getDepth() / 10;
        break;
    }
    this._dropMark.position.z = this._dropMark.scaling.z / 2;
    this._dropMark.position.x = x;
    this._dropMark.setEnabled(true);
  }

  public setDropMarkEnabled(enabled: boolean) {
    this._dropMark.setEnabled(enabled);
  }

  public setDropMarkGreen(green: boolean) {
    if (green && this._dropMark.material.name !== 'drop_green') this._dropMark.material = Scene.CURRENT_SCENE.getMaterialByName('drop_green');
    else if (!green && this._dropMark.material.name !== 'drop_red') this._dropMark.material = Scene.CURRENT_SCENE.getMaterialByName('drop_red');
  }

  public getBlockBoardNode() {
    return this._blockBoardNode;
  }

  public setBlockBoardNode(blockBoardNode: BlockBoardNode) {
    if (blockBoardNode) {
      blockBoardNode.parent = this;
    }
    this._blockBoardNode = blockBoardNode;
    this.updateDimension();
  }

  public setInstallationWall(value: boolean) {
    if (value) {
      if (this._installationWall && this._installationWall.name !== this.getBlock().getInstallationWallId()) this._installationWall.dispose();
      if (!this._installationWall || this._installationWall.isDisposed()) {
        LoaderUtils.loadInstallationWall(
          this,
          node => {
            node.name = this.getBlock().getInstallationWallId();
            // Bottom
            const bl = BasicUtils.findFirstChild('Bottom:PlumbingWall:Left', node);
            const br = BasicUtils.clone(bl, 'Right');
            br.scaling.x = -1;
            // Top
            const tl = BasicUtils.findFirstChild('Top:PlumbingWall:Left', node);
            const tr = BasicUtils.clone(tl, 'Right');
            tr.scaling.x = -1;

            this._installationWall.setEnabled(false);
            this.scaleInstallationWall();
          },
          () => {
            // error?
          }
        );
      } else {
        this.scaleInstallationWall();
      }
    } else {
      if (this._installationWall) this._installationWall.dispose();
      this.bake();
    }
    this.updateDimension();
  }

  public scaleInstallationWall() {
    if (!this._installationWall) return;
    const width = this.getWidth();
    this._installationWall.position.x = -width / 20;
    const b = BasicUtils.findFirstChild('Bottom:PlumbingWall', this._installationWall);
    const t = BasicUtils.findFirstChild('Top:PlumbingWall', this._installationWall);
    const bc = BasicUtils.findFirstChild('Bottom:PlumbingWall:Center', this._installationWall);
    const br = BasicUtils.findFirstChild('Bottom:PlumbingWall:Right', this._installationWall);
    const tc = BasicUtils.findFirstChild('Top:PlumbingWall:Center', this._installationWall);
    const tr = BasicUtils.findFirstChild('Top:PlumbingWall:Right', this._installationWall);
    if (!(b || t || bc || br || tc || tr)) return;

    const scalingC = Math.max(0, width / 100 - 2);
    const positionR = width / 10;

    bc.scaling.x = scalingC;
    tc.scaling.x = scalingC;

    br.position.x = positionR;
    tr.position.x = positionR;

    const scalingZ = this._block.getInstallationWallDepth() / 100;
    b.scaling.z = scalingZ;
    t.scaling.z = scalingZ;

    const bottom = this._block.getBottomHeight();
    const top = BasicUtils.findFirstChild('Top', this._installationWall);
    top.position.y = bottom;
    this._installationWall.position.z = -this._block.getInstallationWallDepth() / 10;

    if (this.getBlock().getInstallationWallHeight() > 0) {
      top.scaling.y = (this.getBlock().getInstallationWallHeight() - this.getBlock().getBottomHeight() * 10) / 790;
    }

    this._installationWall.getChildMeshes().forEach(n => n.setEnabled(true));
    this.updateLabels();
    this.bake();
  }

  public addInstallationWall(wall: BABYLON.TransformNode) {
    if (this._installationWall) this._installationWall.dispose();
    // wall.parent = this;
    wall.setEnabled(false);
    this._installationWall = wall;
  }

  public getTop() {
    return this._top;
  }

  public getBottom() {
    return this._bottom;
  }

  public getBlock() {
    return this._block;
  }

  public isShowLabel() {
    return this._block.isShowLabels();
  }

  public setShowLabel(value: boolean) {
    if (this._labelX) this._labelX.setEnabled(value);
    if (this._labelY) this._labelY.setEnabled(value);
    if (this._labelZ)
      this._labelZ.setEnabled((this._block.getRowTop() || this._block.getInstallationWallDepth() > 0 || this._block.getDepthExtension() > 0) && value);
    this.updateCutLabel();
  }

  public updateLabels() {
    if (this._labelX) this._labelX.dispose();
    if (this._labelY) this._labelY.dispose();
    if (this._labelZ) this._labelZ.dispose();

    const overflowLeft = this._block.isBorderLeftOverflow() ? 50 : 0;
    const overflowRight = this._block.isBorderRightOverflow() ? 50 : 0;

    const x = this._block.getMinWidth();
    const fx = x + overflowLeft + overflowRight;
    const z = this._getDepth();
    this._labelX = LabelUtils.drawLabel('' + fx, fx / 10, Orientation.Bottom);
    this._labelX.parent = this;
    this._labelX.position.x = -this._block.getWidth() / 20 + -overflowLeft / 10;
    this._labelX.position.z =
      -(this._block.getRowBottom().getDepthExtended() + this._block.getDepthExtension() + this._block.getInstallationWallDepth() + 400) / 10;
    this._labelX.setEnabled(this._block.isShowLabels());

    this._labelY = new BABYLON.TransformNode('labelY', this.getScene());
    this._labelY.parent = this;
    const labelYBottom = LabelUtils.drawLabel('' + this._block.getBottomHeight() * 10, this._block.getBottomHeight(), Orientation.Left);
    labelYBottom.parent = this._labelY;
    const labelYTopValue = this.getBlock().getBlockType() === 'ModularNOL' ? 75 : 70;
    const labelYTop = LabelUtils.drawLabel('' + labelYTopValue * 10, labelYTopValue, Orientation.Left);
    labelYTop.parent = this._labelY;
    labelYTop.position.z = this._block.getBottomHeight();
    this._labelY.position.x = -this._block.getWidth() / 20 + -overflowLeft / 10;
    this._labelY.rotation.x = -Math.PI / 2;
    this._labelY.setEnabled(this._block.isShowLabels());

    this._labelZ = LabelUtils.drawLabel('' + z, z / 10, Orientation.Right);
    this._labelZ.parent = this;
    this._labelZ.position.x = (this._block.getWidth() + 700) / 20;
    this._labelZ.position.z = -(this._block.getRowBottom().getDepthExtended() + this._block.getDepthExtension() + this._block.getInstallationWallDepth()) / 10;
    this._labelZ.setEnabled(
      (this._block.getRowTop() || this._block.getInstallationWallDepth() > 0 || this._block.getDepthExtension() > 0) && this._block.isShowLabels()
    );

    this.updateCutLabel();
  }

  private _getDepth() {
    return (
      this._block.getRowBottom().getDepthExtended() +
      this._block.getDepthExtension() +
      this._block.getInstallationWallDepth() +
      (this._block.getRowTop() ? this._block.getRowTop().getDepthExtended() : 0) +
      (this._block.getType() === 'Single' && this._block.getSingleType() === 'Free' ? 40 : 0)
    );
  }

  public isShowCutLabel() {
    return this._block.isShowCutLabels();
  }

  public showCutLabel(value: boolean) {
    this.updateCutLabel();
  }

  public updateCutLabel() {
    const cx = this._block.getCutX();
    const cy = this._block.getCutY();
    if (this._cutLabelTop) {
      this._cutLabelTop.setEnabled(false);
      this._cutLabelTop.dispose();
    }
    if (this._cutLabelBottom) {
      this._cutLabelBottom.setEnabled(false);
      this._cutLabelBottom.dispose();
    }
    if (this._cutLabelLeft) {
      this._cutLabelLeft.setEnabled(false);
      this._cutLabelLeft.dispose();
    }
    if (this._cutLabelRight) {
      this._cutLabelRight.setEnabled(false);
      this._cutLabelRight.dispose();
    }

    if (cy > 0) {
      this._cutLabelTop = new BABYLON.TransformNode('cutLabelTop', Scene.CURRENT_SCENE);
      this._cutLabelTop.parent = this;
      this._cutLabelTop.setEnabled(this._block.isShowCutLabels());
      this._cutLabelTop.position.z = 20;
      if (this._block.getType() === 'Double') this._cutLabelTop.position.z += (this._block.getRowTop().getDepth() + this._block.getDepthExtension()) / 10;

      this._cutLabelBottom = new BABYLON.TransformNode('cutLabelBottom', Scene.CURRENT_SCENE);
      this._cutLabelBottom.parent = this;
      this._cutLabelBottom.setEnabled(this._block.isShowCutLabels());
      this._cutLabelBottom.position.z = -(this._block.getRowBottom().getDepth() + this._block.getDepthExtension()) / 10 - 20;

      const width = this._block.getWidth() / 10;
      const offset = -width / 2;
      for (let i = 0; i < cy; i++) {
        const x = (i + 1) * (width / (cy + 1));

        const top = LabelUtils.drawCutLabel('cutLabelTop' + i, Orientation.Top);
        top.parent = this._cutLabelTop;
        top.position.x = x + offset;

        const bottom = LabelUtils.drawCutLabel('cutLabelBottom' + i, Orientation.Bottom);
        bottom.parent = this._cutLabelBottom;
        bottom.position.x = x + offset;
      }

      if (this._block.isShowLabels()) {
        this._cutLabelTop.position.z += 40;
        this._cutLabelBottom.position.z -= 80;
      }
    }

    if (cx > 0 && this._block.getType() === 'Double') {
      const overflowLeft = this._block.getBorderLeftStyle() === 'BorderAngular' || this._block.getBorderLeftStyle() === 'BorderRound' ? 50 : 0;
      const overflowRight = this._block.getBorderRightStyle() === 'BorderAngular' || this._block.getBorderRightStyle() === 'BorderRound' ? 50 : 0;

      this._cutLabelLeft = LabelUtils.drawCutLabel('cutLabelLeft', Orientation.Left);
      this._cutLabelLeft.parent = this;
      this._cutLabelLeft.setEnabled(this._block.isShowCutLabels());
      this._cutLabelLeft.position.x = -this._block.getWidth() / 20 + -overflowLeft / 10 - 20;

      this._cutLabelRight = LabelUtils.drawCutLabel('cutLabelRight', Orientation.Right);
      this._cutLabelRight.parent = this;
      this._cutLabelRight.setEnabled(this._block.isShowCutLabels());
      this._cutLabelRight.position.x = this._block.getWidth() / 20 + overflowRight / 10 + 20;

      if (this._block.isShowLabels()) {
        this._cutLabelLeft.position.x -= 50;
        this._cutLabelRight.position.x += 80;
      }
    }
  }

  public triggerSideBoardLeftChange() {
    if (this._sideBoardLeft) this._sideBoardLeft.dispose();
    this._sideBoardLeftLoaded = false;
  }

  public triggerSideBoardRightChange() {
    if (this._sideBoardRight) this._sideBoardRight.dispose();
    this._sideBoardRightLoaded = false;
  }

  public getOutputNode() {
    return this?._output;
  }

  public getBounds() {
    return BasicUtils.getBounds(this);
  }

  public getMark() {
    return this._outputMark;
  }

  public bake() {
    HighPerformanceQueue.push(this.uniqueId, async () => {
      if (this.isDisposed()) return false;
      // Clear old output
      {
        const children = this._output.getChildren();
        for (let i = 0; i < children.length; i++) {
          const child = children[i];
          if (child instanceof BABYLON.AbstractMesh) {
            for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
              const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
              shadowGenerator.removeShadowCaster(child);
            }
          }
          child.dispose();
        }
      }
      const nodes: Array<BABYLON.Node> = [];
      const width = this._block.getWidth();
      const depth = this._getDepth();
      const masterline = this.getBlock().isMasterline();

      // Depth Extension
      if (this._block.getDepthExtension() > 0 && this._extension) {
        const base = this._extension;

        // Reposition
        base.position.z = -this._block.getDepthExtension() / 20;
        base.scaling.z = this._block.getDepthExtension() / 100;
        base.position.x = -this._block.getWidth() / 20;

        // Position Top
        const top = BasicUtils.findFirstChild('Top', base);
        top.position.y = this._block.getBottomHeight();
        // Add Cover
        const cover = BasicUtils.findFirstChild('Cover', top);
        cover.scaling.x = width / 100;
        nodes.push(cover);
        // Add Corpus
        const corpus = BasicUtils.findFirstChild('Corpus', top);
        BasicUtils.findFirstChild('Right', corpus).position.x = width / 10;
        nodes.push(corpus);
        // Add Corpus Side Cover
        if (this._block.isSideCover()) {
          const corpusSideCover = BasicUtils.findFirstChild('CorpusSideCover', top);
          const overflowLeft = this._block.isBorderLeftOverflow();
          const overflowRight = this._block.isBorderRightOverflow();
          if (!this._block.getSideBoardRightId() && overflowRight) {
            const right = BasicUtils.findFirstChild('Right', corpusSideCover);
            right.position.x = width / 10;
            BasicUtils.computeAllWorldMatrix(right);
            nodes.push(right);
          }
          if (!this._block.getSideBoardLeftId() && overflowLeft) {
            const left = BasicUtils.findFirstChild('Left', corpusSideCover);
            nodes.push(left);
          }
        }

        // Add Handrail
        switch (this.getBlock()?.getHandle()?.style || '') {
          case 'HandleRound':
            if (this.getBlock().getHandleType() === 'Full') {
              const handle = BasicUtils.findFirstChild('HandleRound_side', top);
              handle.scaling.z = this._block.getDepthExtension() / 100;
              const left = BasicUtils.findFirstChild('Left', handle);
              const right = BasicUtils.findFirstChild('Right', handle);
              right.position.x = width / 10;
              BasicUtils.computeAllWorldMatrix(handle);
              const borderRight = this.getBlock().getBorderRightStyle();
              const borderLeft = this.getBlock().getBorderLeftStyle();
              if (borderLeft.indexOf('BorderRound') >= 0 || borderLeft.indexOf('Border45') >= 0) nodes.push(left);
              if (borderRight.indexOf('BorderRound') >= 0 || borderRight.indexOf('Border45') >= 0) nodes.push(right);
            }
            break;
        }

        // Add PedestrialCover
        switch (this._block.getBottom()) {
          case 'BaseMKN':
          case 'Base':
            const cover = BasicUtils.findFirstChild('PedestrialCover', base);
            BasicUtils.findFirstChild('Right', cover).position.x = width / 10;
            nodes.push(cover);
            break;
          case 'Feet':
            break;
        }

        // Add Border
        const borderLeft = this._block.getBorderLeftStyle();
        const borderRight = this._block.getBorderRightStyle();
        if (borderLeft) {
          const node = BasicUtils.findFirstChild(borderLeft + ':Left', top);
          if (node) {
            nodes.push(node);
          }
        }
        if (borderRight) {
          const node = BasicUtils.findFirstChild(borderRight + ':Right', top);
          if (node) {
            node.position.x = width / 10;
            nodes.push(node);
          }
        }

        BasicUtils.computeAllWorldMatrix(base);
      }

      if (this._installationWall) {
        BasicUtils.computeAllWorldMatrix(this._installationWall);
        nodes.push(this._installationWall);
      }

      // Side Board
      // - Left
      const sideBoardDepth = depth - 10 - (this._block.getRowTop() ? 10 : 0);
      if (this._block.getSideBoardLeftObject()) {
        // Load 3D Model
        try {
          if (!this._sideBoardLeftLoaded) {
            if (this._sideBoardLeft) this._sideBoardLeft.dispose();
            const r = await LoaderUtils.load(`${process.env.REACT_APP_API_URL}/device/get/${this._block.getSideBoardLeftId()}/model/object`);
            r.model.parent = DeviceNode.defaultSettings.modelNode;
            this._sideBoardLeft = r.model;
            this._sideBoardLeft.name = this._block.getSideBoardLeftId();
            this._sideBoardLeftLoaded = true;
          }
        } catch (e) {
          // Nope
          this._sideBoardLeftLoaded = true;
        }
        // Attach it!
        if (this._sideBoardLeft) {
          let offsetX = 0;
          switch (this._block.getBorderLeftStyle()) {
            case 'BorderAngular':
            case 'BorderRound':
              // offsetX = 4;
              break;
          }

          this._sideBoardLeft.position.x = -width / 20 - offsetX;
          this._sideBoardLeft.position.y = this._block.getBottomHeight();
          this._sideBoardLeft.position.z =
            (this._block.getRowTop()
              ? this._block.getRowTop().getDepthExtended() < this._block.getRowBottom().getDepthExtended()
                ? -Math.abs(this._block.getRowBottom().getDepthExtended() - this._block.getRowTop().getDepthExtended()) / 20
                : this._block.getRowTop().getDepthExtended() > this._block.getRowBottom().getDepthExtended()
                ? Math.abs(this._block.getRowBottom().getDepthExtended() - this._block.getRowTop().getDepthExtended()) / 20
                : 0
              : -this._block.getRowBottom().getDepthExtended() / 20) -
            this._block.getDepthExtension() / 20 +
            (this._block.getType() === 'Single' && this._block.getSingleType() === 'Free' ? 2.5 : 0);
          this._sideBoardLeft.scaling.z = sideBoardDepth / 700;
          this._sideBoardLeft.scaling.x = this._block.getSideBoardLeftWidth() / 300;

          BasicUtils.computeAllWorldMatrix(this._sideBoardLeft);
          nodes.push(this._sideBoardLeft);
        }
      }

      // - Right
      if (this._block.getSideBoardRightObject()) {
        // Load 3D Model
        try {
          if (!this._sideBoardRightLoaded) {
            if (this._sideBoardRight) this._sideBoardRight.dispose();
            const r = await LoaderUtils.load(`${process.env.REACT_APP_API_URL}/device/get/${this._block.getSideBoardRightId()}/model/object`);
            r.model.parent = DeviceNode.defaultSettings.modelNode;
            this._sideBoardRight = r.model;
            this._sideBoardRight.name = this._block.getSideBoardRightId();
            this._sideBoardRightLoaded = true;
          }
        } catch (e) {
          // Nope
          this._sideBoardRightLoaded = true;
        }
        // Attach it!
        if (this._sideBoardRight) {
          let offsetX = 0;
          switch (this._block.getBorderLeftStyle()) {
            case 'BorderAngular':
            case 'BorderRound':
              // offsetX = 4;
              break;
          }

          this._sideBoardRight.position.x = width / 20 + offsetX;
          this._sideBoardRight.position.y = this._block.getBottomHeight();
          this._sideBoardRight.position.z =
            (this._block.getRowTop()
              ? this._block.getRowTop().getDepthExtended() < this._block.getRowBottom().getDepthExtended()
                ? -Math.abs(this._block.getRowBottom().getDepthExtended() - this._block.getRowTop().getDepthExtended()) / 20
                : this._block.getRowTop().getDepthExtended() > this._block.getRowBottom().getDepthExtended()
                ? Math.abs(this._block.getRowBottom().getDepthExtended() - this._block.getRowTop().getDepthExtended()) / 20
                : 0
              : -this._block.getRowBottom().getDepthExtended() / 20) -
            this._block.getDepthExtension() / 20 +
            (this._block.getType() === 'Single' && this._block.getSingleType() === 'Free' ? 2.5 : 0);
          this._sideBoardRight.scaling.z = sideBoardDepth / 700;
          this._sideBoardRight.scaling.x = -this._block.getSideBoardRightWidth() / 300;

          BasicUtils.computeAllWorldMatrix(this._sideBoardRight);
          nodes.push(this._sideBoardRight);
        }
      }

      // Merge
      const materialMap = new Map<string, BABYLON.Mesh[]>();
      const materialMapKeys = new Array<string>();
      for (let i = 0; i < nodes.length; i++) {
        const element = nodes[i];
        const childMeshes = element.getChildMeshes();
        for (let i = 0; i < childMeshes.length; i++) {
          const mesh = childMeshes[i];
          if (mesh instanceof BABYLON.Mesh && mesh.material) {
            if (materialMap.has(mesh.material.name)) {
              materialMap.get(mesh.material.name).push(mesh);
            } else {
              materialMap.set(mesh.material.name, [mesh]);
              materialMapKeys.push(mesh.material.name);
            }
          }
        }
      }
      for (let i = 0; i < materialMapKeys.length; i++) {
        const mat = materialMapKeys[i];
        const meshes = materialMap.get(mat);
        const mergedMesh = BABYLON.Mesh.MergeMeshes(meshes, false, true, undefined, false);
        mergedMesh.name = 'mesh-' + mat;
        mergedMesh.parent = this._output;
        for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
          const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
          shadowGenerator.addShadowCaster(mergedMesh);
        }
        for (let i = 0; i < DeviceNode.defaultSettings.mirrors.length; i++) {
          const mirror = DeviceNode.defaultSettings.mirrors[i];
          mirror.renderList.push(mergedMesh);
        }
        mergedMesh.receiveShadows = true;
        if (mat === '_metal_error') mergedMesh.renderOverlay = true;
        else if (masterline && this.getBlock().isFullBlendColor() && mergedMesh.name === 'mesh-_metal_blue_panel') {
          mergedMesh.material = this.getBlock().getBlendColorMaterial();
        } else if (masterline && mergedMesh.name === 'mesh-_metal_blue_panel_top') {
          mergedMesh.material = this.getBlock().getBlendColorMaterial();
        }
      }

      const bounds = this.getBounds();
      // console.log('Merged Bounds', bounds);
      // Add Marks
      let enabledMark = false;
      if (this._outputMark && !this._outputMark.isDisposed()) {
        enabledMark = this._outputMark.isEnabled();
        this._outputMark.dispose();
      }
      this._outputMark = new BABYLON.TransformNode('output', this.getScene());
      this._outputMark.parent = this;
      const w = this._block.getWidth() / 10 + 10;
      const h = 70 + this._block.getBottomHeight() + 0.5;
      const d = this._getDepth() / 10 + 10;
      const ow = -w / 2;
      const oh = -0.5;
      const od =
        ((this._block.getRowTop() ? this._block.getRowTop().getDepthExtended() : 0) +
          this._block.getDepthExtension() +
          this._block.getInstallationWallDepth()) /
          10 -
        5;
      MarkUtils.buildMark({
        width: w,
        height: h,
        depth: d,
        offset: new BABYLON.Vector3(ow, oh, od),
        parent: this._outputMark
      });
      this._outputMark.setEnabled(enabledMark);

      return true;
    });
  }

  public dispose() {
    if (this._installationWall) this._installationWall.dispose();
    if (this._extension) this._extension.dispose();
    if (this._sideBoardLeft) this._sideBoardLeft.dispose();
    if (this._sideBoardRight) this._sideBoardRight.dispose();
    super.dispose();
  }
}
